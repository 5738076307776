import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--shippingtruck';
const ICON_ID = 'ic_shipping_truck';

const IconShippingTruck = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 16 16"
    ariaHidden
    iconId={ICON_ID}
    width="16px"
    height="16px"
  />
);

IconShippingTruck.propTypes = {
  className: string,
  style: string,
};

export default IconShippingTruck;
