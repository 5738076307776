import React from 'react';
import { string } from 'prop-types';

import { loadable } from 'nordic/lazy';
import IconEdit from '@andes/icons/Edit16';
import ThumbUpOutlined from '@andes/icons/ThumbUpOutlined16';
import ThumbDownOutlined from '@andes/icons/ThumbDownOutlined16';
import OverflowMenuVertical from '@andes/icons/OverflowMenuVertical16';
import ThumbUpFilled from '@andes/icons/ThumbUpFilled16';

import listIcon from './list-icons';

const iconsByAndes = {
  thumb_up_filled: ThumbUpFilled,
  dislike_outlined: ThumbDownOutlined,
  like_outlined: ThumbUpOutlined,
  pen: IconEdit,
  vertical_menu: OverflowMenuVertical,
};

const getIcon = (props) => {
  let IconComponent = null;

  if (listIcon[props.iconId]) {
    IconComponent = loadable(async () => import(`../sources/${listIcon[props.iconId]}`));
  }

  if (iconsByAndes[props.iconId]) {
    IconComponent = iconsByAndes[props.iconId];
  }

  return IconComponent ? <IconComponent {...props} /> : null;
};

getIcon.propTypes = {
  className: string,
  color: string,
  description: string,
  height: string,
  iconId: string.isRequired,
  key: string,
  size: string,
  width: string,
};

export default getIcon;
