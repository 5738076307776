/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';

import { isLocalStorageSupported } from '../lib/dom-utils';

const useTooltip = (id, expiredTime = 0) => {
  const localStorageSupported = isLocalStorageSupported();

  const initialState = { wasClosed: false, views: 0, createdDate: null, countClose: 0, isPermanentClosed: false };
  const [tooltipStatus, setTooltipStatus] = useState(initialState);
  const [isTooltipLoad, setIsTooltipLoad] = useState(false);

  function hasTTLExpired() {
    const currentTime = Date.now() / 1000;
    const createdTime = tooltipStatus?.createdDate;
    const elapsedTime = currentTime - createdTime / 1000;

    return elapsedTime > expiredTime;
  }

  function updateTooltipStatus(idTooltip, status) {
    if (localStorageSupported) {
      localStorage.setItem(idTooltip, JSON.stringify(status));
    }

    setTooltipStatus(status);
  }

  function handleClose() {
    const newStatus = {
      wasClosed: true,
      views: tooltipStatus.views,
      createdDate: Date.now(),
      countClose: (tooltipStatus?.countClose || 0) + 1,
      isPermanentClosed: false,
    };

    updateTooltipStatus(id, newStatus);
  }

  function handleOnNext() {
    const newStatus = {
      wasClosed: true,
      views: tooltipStatus.views,
      createdDate: Date.now(),
      countClose: tooltipStatus?.countClose,
      isPermanentClosed: true,
    };

    updateTooltipStatus(id, newStatus);
  }

  useEffect(() => {
    if (localStorageSupported) {
      const localStorageStatusStr = localStorage.getItem(id);

      const localStorageStatus = localStorageStatusStr ? JSON.parse(localStorageStatusStr) : initialState;

      localStorageStatus.views += 1;

      try {
        localStorage.setItem(id, JSON.stringify(localStorageStatus));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving in localStorage');
      }

      setTooltipStatus(localStorageStatus);
      setIsTooltipLoad(true);
    }
  }, [localStorageSupported]);

  return [tooltipStatus, handleClose, hasTTLExpired, handleOnNext, isTooltipLoad];
};

export default useTooltip;
