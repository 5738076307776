import { node, string } from 'prop-types';

import EshopFactory from './utils/eshop-factory';
import withComponentHandler from '../../hocs/with-component-handler';

/**
 * @typedef {Object} EshopComponentProps
 * @property {React.ReactNode} children - Los componentes hijos que serán renderizados.
 * @property {string} siteId - El identificador del sitio.
 */

/**
 * Componente que envuelve y procesa los componentes hijos utilizando `EshopFactory`.
 *
 * @param {EshopComponentProps} props - Las propiedades del componente.
 * @returns {React.ReactNode|null} Los componentes procesados o `null` si no hay hijos.
 */
const componentCreation = (comp, siteId) => EshopFactory({ ...comp, siteId });

/**
 * @typedef {Object} WrapperEshopDesktopProps
 * @property {React.ReactNode} children - The child components.
 * @property {string} siteId - The site identifier.
 */

const WrapperEshopDesktop = ({ children, siteId }) => {
  const componentWrapper = children?.map((component) => componentCreation(component, siteId));

  return componentWrapper || null;
};

WrapperEshopDesktop.propTypes = {
  children: node.isRequired,
  siteId: string.isRequired,
};

export const EshopDesktop = withComponentHandler(WrapperEshopDesktop, { componentName: 'WrapperEshopDesktop' });
