import { node } from 'prop-types';

import { FREESHIPPING_SNACKBAR_DURATION } from './constants';
import useEshopsEnvironment from '../../hooks/use-eshops-environment';
import useFullFreeshipping from './use-full-freeshipping';

const FullFreeshippingSwitcher = ({ eshopsComponent = undefined, defaultComponent = undefined }) => {
  const { isEshopsEnvironment } = useEshopsEnvironment();
  const { freeshippingSnackbarDuration } = useFullFreeshipping();

  return isEshopsEnvironment && freeshippingSnackbarDuration !== FREESHIPPING_SNACKBAR_DURATION.SHORT
    ? eshopsComponent
    : defaultComponent;
};

FullFreeshippingSwitcher.propTypes = {
  defaultComponent: node,
  eshopsComponent: node,
};

export default FullFreeshippingSwitcher;
