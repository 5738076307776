/* eslint-disable  @typescript-eslint/default-param-last */
import APIService from '../../../../../services/cartShops';
import { trackEvent as trackMelidataEvent } from '../../../../lib/melidata';

const ServiceAddToCart = async (
  itemId,
  variation_id,
  quantityCart,
  parentHooks,
  requestQueue = {},
  previousQuantity,
  mshopsTrackData,
) => {
  const { apiError, processRequest, setFirstRequest, setQuantityCartPrevious, setPageXUrl } = parentHooks;

  const request = async () => {
    if (requestQueue) {
      requestQueue.isRequestInProgress = true;
    }

    let action = 'addToCart';

    if (quantityCart > 0) {
      if (previousQuantity === 0) {
        action = 'addToCart';
      } else {
        action = 'updateCartItem';
      }
    } else {
      action = 'deleteCartItem';
    }

    try {
      const { data } = await APIService[action](itemId, quantityCart, variation_id, null);

      const { url = null } = data ?? {};

      setQuantityCartPrevious({ itemId, quantity: quantityCart });

      const updateCartBadgeAndTrack = async () => {
        const { CartBadge, tracking_mediator: trackingMediator } = window;

        if (action !== 'deleteCartItem') {
          trackMelidataEvent({
            path: '/search/add_cart_action',
            event_data: {
              item_id: itemId,
              quantity: quantityCart,
              seller_id: mshopsTrackData.shop_id,
              category_id: mshopsTrackData.category,
            },
          });
        }

        setPageXUrl(url);

        if (CartBadge) {
          let cartItemsCount = CartBadge.itemsQuantity + (quantityCart - previousQuantity);

          try {
            const { data: cartItems } = await APIService.getCartItems();

            cartItemsCount = cartItems.reduce((totalCount, cartItem) => totalCount + cartItem.quantity, 0);
          } finally {
            CartBadge.setBadgeItems(cartItemsCount);
          }
        }

        if (trackingMediator) {
          const trackData = {
            currency: mshopsTrackData.price?.currency,
            price: mshopsTrackData.price?.value,
            title: mshopsTrackData.title,
            item_id: itemId,
            variation_id,
            brand: mshopsTrackData.brand || '',
            name: mshopsTrackData.title,
            quantity: quantityCart,
          };

          trackingMediator('dispatch', 'item:info', trackData);
        }
      };

      setFirstRequest(false);
      processRequest(updateCartBadgeAndTrack);
    } catch (error) {
      if (requestQueue) {
        requestQueue.isRequestInProgress = false;
      }

      apiError();
    }
  };

  if (requestQueue?.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    await request();
  }
};

export default ServiceAddToCart;
