const LABEL_COMPONENTS = {
  ICON: {
    key: 'meli_icon',
    text: '{meli_icon}',
    type: 'ICON',
    name: 'icons',
  },
  PRICE: {
    key: 'meli_prices',
    text: '{meli_prices}',
    type: 'PRICE',
    name: 'amount',
  },
  DEFAULT: {
    key: 'meli_default',
    text: '{meli_default}',
    type: 'DEFAULT',
    name: 'text',
  },
  LABEL: {
    key: 'meli_label',
    text: '{meli_label}',
    type: 'LABEL',
    name: 'suffix_text',
  },
};

const ODR_BASE_URL = 'https://mobile.mercadolibre.com/remote_resources/image/';

export { LABEL_COMPONENTS, ODR_BASE_URL };
