import React from 'react';
import { bool, func, number, shape } from 'prop-types';

import classnames from 'classnames';
import { Tooltip as AndesTooltip } from '@andes/tooltip';

import Actions from '../../../../components/rescue/components/actions';
import { MOBILE_DEFAULT_PROPS, MOBILE_PROP_TYPES, namespace } from './constants';
import { useMobileFilterTooltip } from './hooks/use-mobile-filter-tooltip';

export const FilterTooltip = ({
  className = '',
  children,
  mask = false,
  maskClosable = true,
  onClose,
  tooltip = MOBILE_DEFAULT_PROPS.tooltip,
  tooltipStatus = MOBILE_DEFAULT_PROPS.tooltipStatus,
  hasTTLExpired,
  onPermanentClose,
}) => {
  const { title, offset_x, offset_y, content, actions, side } = tooltip ?? MOBILE_DEFAULT_PROPS.tooltip;

  const { isVisible, isTooltipEligibleDisplay, handleOnCloseTooltip, handleActionClick } = useMobileFilterTooltip({
    tooltip,
    tooltipStatus,
    hasTTLExpired,
    onClose,
    onPermanentClose,
  });

  return (
    <AndesTooltip
      actions={<Actions actions={actions} actionButton={handleActionClick} />}
      className={classnames(namespace, className)}
      closable
      onClose={handleOnCloseTooltip}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      mask={mask}
      maskClosable={maskClosable}
      side={side}
      type="highlight"
      trigger={isTooltipEligibleDisplay ? 'click' : 'no-trigger'}
      visible={isVisible}
    >
      <div className={classnames({ 'andes-tooltip__trigger--visible': isVisible })}>{children}</div>
    </AndesTooltip>
  );
};

FilterTooltip.propTypes = {
  ...MOBILE_PROP_TYPES,
  hasTTLExpired: func,
  tooltipStatus: shape({
    wasClosed: bool.isRequired,
    views: number.isRequired,
    countClose: number,
    isPermanentClosed: bool,
  }).isRequired,
  onClose: func.isRequired,
  onPermanentClose: func,
};
