import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--alert-new';
const ICON_ID = 'ic_alert_new';

const IconAlertNew = (props) => (
  <BuildIcon
    {...props}
    dataTestId="create_alert_icon"
    className={classnames(namespace, props.className)}
    viewBox="0 0 32 32"
    iconId={ICON_ID}
  />
);

IconAlertNew.propTypes = {
  className: string,
};

export default IconAlertNew;
