import buildText from './build-label-text';
import buildValues from './build-label-values';

const getLabelBuilderProps = (prop) => {
  if (!prop?.text) {
    return null;
  }

  return { textBuilder: buildText(prop), valuesBuilder: buildValues(prop) };
};

export default getLabelBuilderProps;
