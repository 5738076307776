import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--question';
const ICON_ID = 'ic_question';

const IconQuestion = (props) => (
  <BuildIcon
    {...props}
    viewBox="0 0 17 16"
    iconId={ICON_ID}
    className={classnames(namespace, props.className)}
    width="17"
    height="16"
  />
);

IconQuestion.propTypes = {
  className: string,
  style: string,
};

export default IconQuestion;
