import React from 'react';

import classnames from 'classnames';
import warning from 'warning';
import isObject from 'lodash/isObject';

import getIcon from './get-icon';

const IS_DEV = process.env.NODE_ENV !== 'production';

const IconFactory = () => (icon, className) => {
  let id = `${icon}`;

  if (isObject(icon) && typeof icon.id === 'string') {
    id = icon.id.toLowerCase();
  }

  const Component = getIcon({ iconId: id, className: classnames(className), description: icon.description });

  if (!Component && IS_DEV) {
    warning(false, `icon "${id}" not found`);
  }

  return Component;
};

export default IconFactory;
