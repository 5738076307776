import { useEffect, useState, useCallback } from 'react';

import { trackEvent } from '../../../../../lib/melidata';
import { isTTLActive } from '../helpers';
import { MOBILE_DEFAULT_PROPS } from '../constants';

export const useMobileFilterTooltip = ({ tooltip, tooltipStatus, hasTTLExpired, onClose, onPermanentClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { filter_content_id, max_print, with_ttl, close_limit, tracks } = tooltip ?? MOBILE_DEFAULT_PROPS.tooltip;

  const { wasClosed, views, countClose, isPermanentClosed, createdDate } =
    tooltipStatus ?? MOBILE_DEFAULT_PROPS.tooltipStatus;

  const isTooltipEligibleDisplay = !(wasClosed || views > max_print || !filter_content_id);

  const handleOnCloseTooltip = useCallback(() => {
    setIsVisible(false);

    if (tracks?.close) {
      trackEvent({ clean: true, ...tracks.close });
    }

    onClose?.();
  }, [tracks, onClose]);

  const handleActionClick = useCallback(
    (handleCustomClick, action) => {
      handleCustomClick?.();

      if (action?.isPermanentClosed) {
        onPermanentClose?.();
      } else {
        onClose?.();
      }
    },
    [onClose, onPermanentClose],
  );

  useEffect(() => {
    let shouldBeVisible = isTooltipEligibleDisplay;

    if (with_ttl) {
      const tooltipValidation = {
        isPermanentClosed,
        with_ttl,
        hasTTLExpired,
        closeLimit: close_limit,
        countClose,
        createdDate,
      };

      shouldBeVisible = isTTLActive(tooltipValidation);
    }

    setIsVisible(shouldBeVisible);

    if (tracks?.show && shouldBeVisible) {
      trackEvent({ clean: true, ...tracks.show });
    }
  }, [
    tracks,
    hasTTLExpired,
    close_limit,
    countClose,
    isPermanentClosed,
    with_ttl,
    createdDate,
    wasClosed,
    isTooltipEligibleDisplay,
  ]);

  return {
    isVisible,
    isTooltipEligibleDisplay,
    handleOnCloseTooltip,
    handleActionClick,
  };
};
