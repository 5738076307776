/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { node, number, string } from 'prop-types';

import debounce from 'lodash/debounce';

const AutoHeight = ({ className, children, initialHeight = 500 }) => {
  const [height, setHeight] = useState(initialHeight);
  const container = useRef();

  const resizeContainer = useCallback(() => {
    if (container.current) {
      setHeight(window.innerHeight - container.current.offsetTop);
    }
  }, []);

  const handleResize = useCallback(debounce(resizeContainer, 1000), [resizeContainer]);

  useLayoutEffect(() => {
    if (container.current && window) {
      resizeContainer();
    }
  }, [resizeContainer]);

  useLayoutEffect(() => {
    if (!MutationObserver) {
      return () => {};
    }

    const observer = new MutationObserver(resizeContainer);

    if (container.current?.parentNode) {
      observer.observe(container.current.parentNode, { childList: true });
    }

    return () => observer.disconnect();
  }, [resizeContainer]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [handleResize]);

  return (
    <div className={className} style={{ height }} ref={container}>
      {children}
    </div>
  );
};

AutoHeight.propTypes = {
  children: node.isRequired,
  className: string,
  initialHeight: number,
};

export default AutoHeight;
