import { string, shape, arrayOf, number } from 'prop-types';

const TEXT = shape({
  processed_text: string,
  icon_position: string,
});

const ICON = arrayOf(string);

const AMOUNT = shape({
  price: string,
  currency_id: string,
});

const SWITCH_FILTERS_PROPS_TYPES = shape({
  highlight: shape({
    icon: shape({
      id: string.isRequired,
      color: string,
      highlight_position: string,
    }),
    label: shape({
      text: string.isRequired,
      color: string,
      background: string,
      size: number,
      weight: number,
      style: string,
    }),
  }),
  htmlForIDValue: string,
  label: shape({
    text: string,
  }),
  title: shape({
    text: TEXT,
    icon: ICON,
    title_suffix: shape({
      text: TEXT,
      icon: ICON,
    }),
  }),
  subtitle: shape({
    text: TEXT,
    icon: ICON,
    amount: AMOUNT,
  }),
  namespace: string,
  srLabel: string,
  style: shape({ width: string, height: string }),
});

const SWITCH_FILTERS_LABEL_PROPS_TYPES = shape({
  title: shape({
    className: string,
    icon: ICON,
    suffix_text: string,
    text: TEXT,
    titleLabelBuilderProps: shape({
      textBuilder: string,
      valuesBuilder: arrayOf(
        shape({
          key: string,
          type: string,
          text: string,
          icon_id: string,
          src: string,
        }),
      ),
    }),
  }),
  subtitle: shape({
    className: string,
    icon: ICON,
    text: TEXT,
    amount: AMOUNT,
    subtitleLabelBuilderProps: shape({
      textBuilder: string,
      valuesBuilder: arrayOf(
        shape({
          key: string,
          type: string,
          text: string,
          icon_id: string,
          src: string,
        }),
      ),
    }),
  }),
  highlight: shape({
    icon: shape({
      id: string.isRequired,
      color: string,
      highlight_position: string,
    }),
    label: shape({
      text: TEXT,
      color: string,
      background: string,
      size: number,
      weight: number,
      style: string,
      styles: shape({}),
    }),
    suffix_text: shape({
      text: string,
      styles: shape({}),
    }),
    highlightLabelBuilderProps: shape({
      textBuilder: string,
      valuesBuilder: arrayOf(
        shape({
          key: string,
          type: string,
          text: string,
          icon_id: string,
          styles: shape({}),
          src: string,
        }),
      ),
    }),
  }),
  label: shape({
    text: TEXT,
    labelLabelBuilderProps: shape({
      textBuilder: string,
      valuesBuilder: arrayOf(
        shape({
          key: string,
          type: string,
          text: string,
          icon_id: string,
          src: string,
        }),
      ),
    }),
  }),
});

export { SWITCH_FILTERS_PROPS_TYPES, SWITCH_FILTERS_LABEL_PROPS_TYPES };
