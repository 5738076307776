import React, { useMemo } from 'react';
import { arrayOf, oneOf, shape, string } from 'prop-types';

import classnames from 'classnames';

import { splitVariablesAndText } from '../utils/utilities';
import renderComponentsFromBuilder from '../utils/renderComponentsFromBuilder';
import { PACKAGE_NAMESPACE_LABEL } from '../constants';
/**
 * @typedef {Object} LabelContentProps
 * @property {string} [html_tag] - La etiqueta HTML que se usará para renderizar el contenido (por ejemplo, 'span', 'div').
 * @property {Object[]} [sourceIcons] - Los íconos de origen que se usarán en el contenido.
 * @property {string} [text] - El texto que se renderizará, incluyendo variables.
 * @property {Object[]} [values] - Los valores asociados a las variables en el texto.
 * @property {string} [class_name] - Clases CSS adicionales para el componente.
 */

/**
 * Componente que renderiza contenido de etiqueta con soporte para variables y componentes dinámicos.
 *
 * @param {LabelContentProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el contenido de la etiqueta.
 */
const LabelContent = (props) => {
  const { html_tag, sourceIcons, text, values, class_name } = props;
  const Element = html_tag || 'span';

  const result = splitVariablesAndText(text);

  const componentsToRender = useMemo(
    () => renderComponentsFromBuilder({ result, values, props, sourceIcons }),
    [result, values, props, sourceIcons],
  );

  return <Element className={classnames(PACKAGE_NAMESPACE_LABEL, class_name)}>{componentsToRender}</Element>;
};

LabelContent.propTypes = {
  class_name: string,
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  sourceIcons: arrayOf(
    shape({
      className: string,
      color: string,
      description: string,
      icon: string.isRequired,
      key: string,
      size: string,
    }),
  ),
  text: string,
  values: arrayOf(
    shape({
      className: string,
      color: string,
      description: string,
      icon_id: string,
      key: string,
      text: string,
      type: string,
      styles: shape({}),
    }),
  ),
};

export default LabelContent;
