import { string, shape, number, bool } from 'prop-types';

const ERROR_CONTEXT_PROPS_TYPES = shape({
  team: string,
  uniqueErrorCode: string,
  visualType: string,
  number: string,
  path: string,
  appName: string,
  statusCode: number,
  appVersion: string,
  application: string,
  detail: string,
  deviceData: shape({}),
  env: string,
  isTest: bool,
  requestId: string,
  site: string,
});

export default ERROR_CONTEXT_PROPS_TYPES;
