import { bool, shape, string } from 'prop-types';

const FILTER_BASE_PROPS = {
  highlight: shape(),
  label: shape(),
  subtitle: shape(),
  title: shape(),
  tooltip: shape(),
  hasHtml: bool,
};

const FILTER_EXTENDED_PROPS = {
  ...FILTER_BASE_PROPS,
  switch: shape({ is_on: bool }),
  url: string,
};

const NAMESPACE = 'ui-search-filter-highlighted';
const MELI_PLUS = 'meli_plus';

const DEFAULT_PROPS = {
  DEFAULT_INITIAL_EXPANDED_VALUE: [],
};

const QUESTION_ICON_ID = 'question';

const APPROVED_LOAN = 'approved_loan_highlighted';

export {
  FILTER_BASE_PROPS,
  FILTER_EXTENDED_PROPS,
  NAMESPACE,
  DEFAULT_PROPS,
  MELI_PLUS,
  QUESTION_ICON_ID,
  APPROVED_LOAN,
};
