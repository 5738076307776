import React from 'react';

import RenderFilterRange from './filter-range';
import { FILTERS } from '../../../../../../constants';
import { objRangeContent } from '../../prop-types';

const { TYPES } = FILTERS;

const RangeContentPi = ({ id, values, url_templates, type, activeCurrencyId, labels, fragment }) =>
  url_templates && (type === TYPES.RANGE || type === TYPES.PRICE_WITH_CONVERSION) ? (
    <li>
      <RenderFilterRange
        values={values}
        url_templates={url_templates}
        labels={labels}
        modifier={id}
        type="input"
        activeCurrencyId={activeCurrencyId}
        fragment={fragment}
      />
    </li>
  ) : null;

RangeContentPi.propTypes = {
  ...objRangeContent,
};

export default RangeContentPi;
