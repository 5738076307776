export const SplitHelperText = (title) => {
  const ValidKeys = ['</br>', '{other_line}'];

  let newobjText;

  ValidKeys.forEach((key) => {
    if (title.valuesBuilder?.[0].text.includes(key)) {
      const splitText = title.valuesBuilder?.[0].text.split(key);

      const newObj = {
        ...title,
        textBuilder: `${title.textBuilder} {other_line} {meli_label}`,
        valuesBuilder: [{ ...title.valuesBuilder[0], text: splitText[0] }],
      };

      newObj.valuesBuilder.push({
        ...title.valuesBuilder[0],
        text: splitText[1],
        key: 'meli_label',
      });

      newObj.valuesBuilder.push({
        text: '',
        key: 'other_line',
        type: 'text',
        className: `line-break`,
      });

      newobjText = newObj;
    }
  });

  return newobjText || title;
};
