import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--map-zoom-out';

const IconZoomOut = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 16 16"
    color={props.color ?? '#3483FA'}
  >
    <path d="M1.99902 8.60039H13.999V7.40039H1.99902V8.60039Z" />
  </BuildIcon>
);

IconZoomOut.propTypes = {
  className: string,
  color: string,
};

export default IconZoomOut;
