import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import IconLabel from '../../../../icons/utils/icon-label';
import Modal from '../../../../../components/modal/base.mobile';
import useModal from '../../../../../hooks/use-modal';
import { filterPropTypes } from '../prop-types';
import SearchModalContainer from '../../search-modal';
import MoneyPicker from '../../../../money-picker/desktop';
import { useStaticProps } from '../../../../../components/context/static-props';
import { useSearch } from '../../../../../hooks/context';
import { hasFilters, setHideModal } from '../../../../../components/context/filters';
import { FILTERS } from '../../../../../constants';
import RangeContent from './filter-range/range-content';
import FilterValuesContent from './filter-values-content';
import RangeContentPI from './filter-range/range-content-pi';
import getModalModifier from '../utils/get-modal-modifier';
import getActiveCurrency from '../utils/get-active-currency';
import withComponentHandler from '../../../../../hocs/with-component-handler';

const namespace = 'ui-search-filter';
const { IDS, TYPES } = FILTERS;

/**
 * Componente que representa un ítem de filtro en la interfaz de usuario.
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.id - Identificador del filtro.
 * @param {string} props.name - Nombre del filtro.
 * @param {Array} [props.values=[]] - Valores del filtro.
 * @param {Object} props.modal - Configuración del modal.
 * @param {Array} props.expanded_values - Valores expandidos del filtro.
 * @param {Object} props.url_templates - Plantillas de URL.
 * @param {string} props.url - URL del filtro.
 * @param {Array} props.currencies - Monedas disponibles.
 * @param {Array} props.values_with_conversion - Valores con conversión de moneda.
 * @param {string} props.type - Tipo de filtro.
 * @param {Object} props.labels - Etiquetas del filtro.
 * @param {string} props.fragment - Fragmento del filtro.
 * @param {Object} props.icons - Iconos del filtro.
 * @returns {JSX.Element} Componente de ítem de filtro.
 */

const FilterItem = ({
  id,
  name,
  values = [],
  modal,
  expanded_values,
  url_templates,
  url,
  currencies,
  values_with_conversion,
  type,
  labels,
  fragment,
  icons,
} = {}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const hasMoneyPicker = id === IDS.PRICE && currencies;
  const [activeCurrencyId, setActiveCurrencyId] = useState(getActiveCurrency(currencies));
  const { visualId } = useStaticProps();

  const filterRangeRenderer = visualId === 'pi' ? RangeContentPI : RangeContent;
  const [isOpen, openModal, hideModal] = useModal();

  useEffect(() => {
    const withFilters = hasFilters();

    if (withFilters && isOpen) {
      setHideModal(() => hideModal);
    }
  }, [isOpen, hideModal]);

  useEffect(() => {
    setActiveCurrencyId(getActiveCurrency(currencies));
  }, [currencies]);

  return (
    <div className={classnames(`${namespace}-dl`, { 'shops__filter-items': isShops })} key={id}>
      {!hasMoneyPicker &&
        type !== TYPES.HIGHLIGHTED &&
        (icons ? (
          <IconLabel
            aria-level="3"
            className={classnames(`${namespace}-dt-title`, { 'shops-custom-primary-font': isShops })}
            icons={icons}
            text={name}
          />
        ) : (
          <h3 aria-level="3" className={classnames(`${namespace}-dt-title`, { 'shops-custom-primary-font': isShops })}>
            {name}
          </h3>
        ))}
      <ul>
        {hasMoneyPicker && (
          <MoneyPicker
            currencies={currencies}
            filterValues={values_with_conversion}
            title={name}
            activeCurrencyId={activeCurrencyId}
            handleCurrencyChange={setActiveCurrencyId}
          />
        )}
        {FilterValuesContent({ id, name, type, values, expanded_values, currencies, isShops })}
        {modal && (
          <li>
            <Modal
              className={`ui-search-modal--${getModalModifier(id)}`}
              label={modal.labels.modal_label}
              modalUrl="#filter"
              url={url}
              type="small"
              withLink
              open={isOpen}
              openModal={openModal}
              hideModal={hideModal}
              modalTitle={name}
            >
              <SearchModalContainer values={values} id={id} {...modal} title={name} />
            </Modal>
          </li>
        )}
        {filterRangeRenderer({ id, values, url_templates, type, activeCurrencyId, labels, fragment })}
      </ul>
    </div>
  );
};

FilterItem.propTypes = {
  ...filterPropTypes,
};

export default withComponentHandler(FilterItem, { componentName: 'FilterItem' });
