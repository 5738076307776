import React from 'react';
import { string, number, bool, shape } from 'prop-types';

import classNames from 'classnames';
import { MoneyAmount } from '@andes/money-amount';

import { useSearch } from '../../../hooks/context';
import { getFormattedIntFraction, getFormattedDecimalFraction } from '../../../utils/format-price';

const namespace = 'ui-search-price__part';

const getSize = (size) => {
  let value = 0;

  switch (size) {
    case 'large':
      value = 36;

      break;
    case 'tiny':
    case 'small':
    case 'x-tiny':
      value = 16;

      break;
    case 'medium':

    // falls through
    default:
      value = 24;

      break;
  }

  return value;
};

const PricePart = ({
  value,
  thousandSeparator,
  decimalSeparator,
  currencySymbol,
  className = null,
  showDecimals = true,
  strikeThrough = false,
  billing = false,
  size,
  bulksale = null,
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const intFraction = getFormattedIntFraction(value, thousandSeparator);
  const decimalFraction = getFormattedDecimalFraction(value);
  const sizeNum = getSize(size);

  return (
    <MoneyAmount
      className={classNames(namespace, `${namespace}--${size}`, className, { 'shops__price-part': isShops })}
      amount={{
        decimal_separator: decimalSeparator,
        fraction: intFraction,
        symbol: currencySymbol,
        symbol_text: '',
        cents: showDecimals ? decimalFraction : null,
      }}
      suffix={bulksale ? bulksale.unit_measure : ''}
      billing={billing}
      size={sizeNum}
      previous={strikeThrough}
      weight="regular"
      centsType="superscript"
      compact={sizeNum === 16}
    />
  );
};

PricePart.propTypes = {
  billing: bool,
  bulksale: shape({}),
  className: string,
  currencySymbol: string.isRequired,
  decimalSeparator: string.isRequired,
  showDecimals: bool,
  size: string,
  strikeThrough: bool,
  thousandSeparator: string.isRequired,
  value: number.isRequired,
};

export default PricePart;
