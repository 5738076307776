import IconMap from '../../../../icons/sources/map';
import ListIcon from '../../../../icons/sources/list.desktop';

const namespace = 'ui-search-map';

const MAP_LINK = 'MAP_LINK';
const LIST_LINK = 'LIST_LINK';
const DEFAULT = 'DEFAULT';

const TYPE = {
  [LIST_LINK]: { hierarchy: 'quiet', IconComponent: ListIcon },
  [MAP_LINK]: { hierarchy: 'quiet', IconComponent: IconMap },
  [DEFAULT]: { hierarchy: 'quiet' },
};

const namespaceToolbal = 'ui-search-toolbar';

const ICONS = {
  [LIST_LINK]: { IconComponent: ListIcon, className: 'list' },
  [MAP_LINK]: { IconComponent: IconMap, className: 'map' },
};

export { namespace, namespaceToolbal, MAP_LINK, LIST_LINK, DEFAULT, TYPE, ICONS };
