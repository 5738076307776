const replaceObjectProps = (objectToReplace, coincidence, coincidenceCallback) => {
  if (!objectToReplace || typeof objectToReplace !== 'object' || Object.keys(objectToReplace).length === 0) {
    return objectToReplace;
  }

  return Object.keys(objectToReplace).reduce((updatedObject, key) => {
    const newKey = key.includes(coincidence) ? coincidenceCallback(key) : key;

    updatedObject[newKey] = objectToReplace[key];

    return updatedObject;
  }, {});
};

export default replaceObjectProps;
