import React from 'react';
import { bool, string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const NAMESPACE_RAW = 'ui-search-icon';
const NAMESPACE = `${NAMESPACE_RAW} ${NAMESPACE_RAW}--loyalty`;
const ICON_ID = 'ic_loyalty';

const IconLoyalty = (props) => (
  <BuildIcon
    {...props}
    className={classnames(NAMESPACE, props.className, { [`${NAMESPACE_RAW}--hidden`]: props.hidden })}
    viewBox="0 0 32 32"
    iconId={ICON_ID}
  />
);

IconLoyalty.propTypes = {
  className: string,
  hidden: bool,
};

export default IconLoyalty;
