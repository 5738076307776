import React from 'react';

import LabelBuilder from '../../label-builder';
import { SWITCH_FILTERS_LABEL_PROPS_TYPES } from '../constants/prop-types';

const LabelBuilderWrapper = ({ labelProps, labelBuilderProps }) => {
  if (!labelProps || !labelBuilderProps) {
    return null;
  }

  return (
    <LabelBuilder
      text={labelBuilderProps?.textBuilder}
      values={labelBuilderProps?.valuesBuilder}
      class_name={labelProps?.className}
    />
  );
};

LabelBuilderWrapper.propTypes = {
  ...SWITCH_FILTERS_LABEL_PROPS_TYPES,
};

export default LabelBuilderWrapper;
