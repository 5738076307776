const namespaceDesktop = 'ui-search-seo-info-image-text-box-intervention';
const namespaceMobile = 'ui-search-seo-info-image-text-box-intervention-mobile';
const expandablePropsSize = 'small';
const expandablePropsColor = 'link';
const expandablePropsDefaultHeightDesktop = 68;
const expandablePropsDefaultHeightMobile = 70;
const preloadImage = true;
const charCountToExpand = 0;

export {
  namespaceDesktop,
  namespaceMobile,
  expandablePropsSize,
  expandablePropsColor,
  expandablePropsDefaultHeightDesktop,
  expandablePropsDefaultHeightMobile,
  preloadImage,
  charCountToExpand,
};
