import React from 'react';

import { useSearch } from '../../hooks/context';
import Sidebar from './components/sidebar';

const hasMultiNeighborhoods = ({ filters }) => !!filters.find((it) => it.id === 'neighborhood' && it.values.length > 1);

const SidebarContainer = () => {
  const { sidebar } = useSearch();

  if (!sidebar) {
    return null;
  }

  const { components } = sidebar;
  const appliedFilters = components?.find((it) => it.type === 'APPLIED_FILTERS');
  const multiNeighborhood = appliedFilters && hasMultiNeighborhoods(appliedFilters);

  return <Sidebar {...sidebar} multiNeighborhood={multiNeighborhood} />;
};

export default SidebarContainer;
