import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-list-desktop';
const ICON_ID = 'ic_list_desktop';

const IconListDesktop = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 16 16"
    iconId={ICON_ID}
    color={props.color ?? '#3483FA'}
    style={{
      width: '100%',
      height: '100%',
      ...props.style,
    }}
  />
);

IconListDesktop.propTypes = {
  className: string,
  color: string,
  style: string,
};

export default IconListDesktop;
