import React from 'react';

import SeoInfoImageTextBoxInterventionDesktop from './desktop';
import SeoInfoImageTextBoxInterventionMobile from './mobile';
import { DEVICE_TYPES } from '../../../constants/index';
import { useStaticProps } from '../../context/static-props';
import { seoInfoImageTextBoxInterventionPropTypes } from '../../../components-v2/intervention/seo-info-image-text-box-intervention/constants';

const SeoInfoImageTextBox = (props) => {
  const { deviceType } = useStaticProps();
  const isMobile = deviceType === DEVICE_TYPES.MOBILE;

  return isMobile ? (
    <SeoInfoImageTextBoxInterventionMobile {...props} />
  ) : (
    <SeoInfoImageTextBoxInterventionDesktop {...props} />
  );
};

SeoInfoImageTextBox.propTypes = seoInfoImageTextBoxInterventionPropTypes;

export default SeoInfoImageTextBox;
