import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';

import getIcon from '../../../components-v2/icons';

const namespace = 'ui-search-vertical-highlight';

const VerticalHighlight = ({ className, id, icon }) => (
  <div className={classnames(namespace, className, `${namespace}--${id}`)}>
    {getIcon({ iconId: icon.id, color: icon.color })}
  </div>
);

VerticalHighlight.propTypes = {
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }).isRequired,
  id: string.isRequired,
};

VerticalHighlight.defaultProps = {
  className: null,
};

export default VerticalHighlight;
