import { linkBuildNewHref } from '../components-v2/link';

const redirectTo = (url, options = {}) => {
  const { replaceRules = null, useLinkBuilder = false, linkBuilderConfig = {} } = options;

  let updatedUrl = url;

  if (replaceRules) {
    Object.entries(replaceRules).forEach(([searchValue, replaceValue]) => {
      updatedUrl = updatedUrl.replace(searchValue, replaceValue);
    });
  }

  if (useLinkBuilder) {
    const { queryParams = {}, isInternal = true, currentLayout = null } = linkBuilderConfig;

    updatedUrl = linkBuildNewHref({ href: updatedUrl, queryParams, isInternal, currentLayout });
  }

  window.location.href = updatedUrl;
};

export default redirectTo;
