import React from 'react';
import { node, string, shape, number } from 'prop-types';

import classnames from 'classnames';
import { Tooltip as AndesTooltip } from '@andes/tooltip';

import useTooltip from '../../../../hooks/use-tooltip';
import { getFilterTooltipKey } from './helpers';
import { defaultPropTooltip, namespace } from './constants';
import withComponentHandler from '../../../../hocs/with-component-handler';

/**
 * FilterTooltip component that displays a tooltip with additional information.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children elements to wrap with the tooltip.
 * @param {Object} props.tooltip - The tooltip configuration.
 * @param {string} [props.className] - Additional class names for the tooltip.
 * @returns {React.ReactNode} The component with the tooltip.
 */

const FilterTooltip = ({ children, tooltip, className = '' }) => {
  const { title, offset_x, offset_y, content, side, type, max_print, filter_content_id } =
    tooltip ?? defaultPropTooltip;

  const localStorageKey = getFilterTooltipKey({ filter_content_id });
  const [tooltipStatus, handleClose] = useTooltip(localStorageKey);

  const shouldHideTooltip = tooltipStatus?.wasClosed || tooltipStatus?.views > max_print || !tooltip || !content;

  if (shouldHideTooltip) {
    return children;
  }

  return (
    <AndesTooltip
      className={classnames(namespace, className)}
      closable
      onClose={handleClose}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      side={side}
      type={type}
      trigger="no-trigger"
      visible
    >
      {children}
    </AndesTooltip>
  );
};

FilterTooltip.propTypes = {
  children: node.isRequired,
  className: string,
  tooltip: shape({
    title: string,
    offset_x: number,
    offset_y: number,
    filter_content_id: string,
    content: node,
    max_print: number,
  }),
};

export default withComponentHandler(FilterTooltip, { componentName: 'FilterTooltip-desktop' });
