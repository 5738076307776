import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import DynamicAccordionsLinks from '@seo-frontend-components/dynamic-accordions-links';

const namespace = 'ui-search-seo-smart-tabs';

const SeoSmartTabs = (props) => {
  const { tabs = [] } = props;

  if (!tabs.length) {
    return null;
  }

  return (
    <div className={`${namespace}__container`}>
      <DynamicAccordionsLinks {...props} />
    </div>
  );
};

SeoSmartTabs.propTypes = {
  tabs: arrayOf(
    shape({
      title: string,
      links: arrayOf(
        shape({
          url: string,
          label: string,
        }),
      ),
    }),
  ),
  title: string.isRequired,
};

export default SeoSmartTabs;
