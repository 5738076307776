import React from 'react';
import { string } from 'prop-types';

import { BuildIcon } from '../utils';

const ICON_ID = 'ic_coupon';

const IconCoupon = (props) => (
  <BuildIcon {...props} viewBox="0 0 13 11" ariaHidden iconId={ICON_ID} width="13" height="13" />
);

IconCoupon.propTypes = {
  style: string,
};

export default IconCoupon;
