import { FILTER_TOOLTIP_PREFIX } from './constants';

const getFilterTooltipKey = (props) =>
  props?.filter_content_id ? `${FILTER_TOOLTIP_PREFIX}-${props?.filter_content_id}` : '';

const isTTLActive = ({ isPermanentClosed, with_ttl, hasTTLExpired, closeLimit, countClose, createdDate }) => {
  if (createdDate === null || createdDate === undefined) {
    return true;
  }

  if (isPermanentClosed) {
    return false;
  }

  if (with_ttl && (!hasTTLExpired?.() || closeLimit <= countClose)) {
    return false;
  }

  return true;
};

export { getFilterTooltipKey, isTTLActive };
