import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIconImage } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--map-loader-error';

const IconLoaderError = (props) => (
  <div className={classnames(namespace, props.className)}>
    <BuildIconImage {...props} src="loader-error.svg" description={props.description ?? 'Load error icon'} />
  </div>
);

IconLoaderError.propTypes = {
  className: string,
  description: string,
};

export default IconLoaderError;
