import React from 'react';

import FilterRange from '../../../../../../components/filters/range-filters/range-filters.desktop';
import { objRenderFilterRange } from '../../prop-types';

const RenderFilterRange = ({
  activeCurrencyId = '',
  fragment = '',
  labels = null,
  modifier = '',
  type = '',
  url_templates = '',
  values = null,
}) => (
  <FilterRange
    labels={labels}
    values={values}
    url={url_templates}
    activeCurrencyId={activeCurrencyId}
    type={type}
    id={modifier}
    fragment={fragment}
    className={`ui-search-filter-range--${modifier}`}
  />
);

RenderFilterRange.propTypes = {
  ...objRenderFilterRange,
};

export default RenderFilterRange;
