import React from 'react';
import { bool, number, oneOfType, string, node } from 'prop-types';

import cx from 'classnames';

import Link from '../../link';

const DefaultNode = ({ className, children, url }) => (
  <Link className={className} href={url} title="node">
    {children}
  </Link>
);

const SelectedNode = ({ className, children }) => <span className={className}>{children}</span>;

const NodePagination = ({ isActualPage = false, url = '', value = null }) => {
  const NodeComponent = isActualPage ? SelectedNode : DefaultNode;

  return (
    <li
      className={cx('andes-pagination__button', {
        'andes-pagination__button--current': isActualPage,
      })}
    >
      <NodeComponent className="andes-pagination__link" url={url}>
        {value}
      </NodeComponent>
    </li>
  );
};

NodePagination.propTypes = {
  isActualPage: bool,
  url: string,
  value: oneOfType([number, string]).isRequired,
};

DefaultNode.propTypes = {
  children: node,
  className: string,
  url: string,
};

SelectedNode.propTypes = {
  children: node,
  className: string,
};

export default NodePagination;
