import React from 'react';
import { node, oneOfType, shape, string } from 'prop-types';

import { formatStyles } from '../utils/formatStyles';
/**
 * @typedef {Object} LabelProps
 * @property {string} [className] - Clases CSS adicionales para el componente.
 * @property {Object} [styles] - Los estilos en línea aplicados al componente.
 * @property {string|React.ReactNode} [text] - El texto o nodo React que se renderizará dentro del componente.
 */

/**
 * Componente que renderiza una etiqueta con estilos personalizados.
 *
 * @param {LabelProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la etiqueta renderizada.
 */
const Label = ({ text, styles, className }) => (
  <span style={formatStyles(styles)} className={className}>
    {text}
  </span>
);

Label.propTypes = {
  className: string,
  styles: shape({}),
  text: oneOfType([string, node]),
};

export default Label;
