import React from 'react';
import { string } from 'prop-types';

import { BuildIcon } from '../utils';

const ICON_ID = 'ic_search_bookmark';

const SearchBookmarkIcon = (props) => (
  <BuildIcon
    {...props}
    viewBox="0 0 12 16"
    className={props.className}
    iconId={ICON_ID}
    color={props?.color ?? '#3483FA'}
    width="12"
    height="16"
  />
);

SearchBookmarkIcon.propTypes = {
  className: string,
  color: string,
  description: string,
  style: string,
};

export default SearchBookmarkIcon;
