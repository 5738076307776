import React, { useCallback, useState } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';

import FacetedSearchDesktop from 'vis-faceted-search/lib/Desktop';
import { buildInitialState, deserializeState } from 'vis-faceted-search/helpers';

import SecondaryFilters from './secondary-filters/secondary-filters';
import { useSearch } from '../../../../hooks/context';
import useVisFacetedSearch from '../../../../hooks/use-vis-faceted-search';
import { NAMESPACE } from './constants';
import { FILTERS, LAYOUTS, VISIBLE } from '../../../../constants';
import { sendTrack } from '../../../../utils/search/track';
import useMapConfig from '../../hooks/use-map-config';
import { trackClickEvent } from '../../track';
import {
  getIsSecondaryFilterSelected,
  hasFilters,
  setIsSecondaryFilterSelected,
  setLastFilterId,
} from '../../../context/filters';

const { STATE, OPERATION_SUBTYPE: OPST } = FILTERS.IDS;

const Filters = ({ isLoading, filters, newFacetedSearch, onPrimaryFilterChange }) => {
  const isFacetedSearchVisible = newFacetedSearch?.state === VISIBLE;
  const facetedConfig = newFacetedSearch?.faceted_config?.data ?? null;
  const { renderConfig, initialState } = useVisFacetedSearch();
  const { layout_options } = useSearch();
  const [facetedState, setFacetedState] = useState(() =>
    renderConfig ? buildInitialState(renderConfig, initialState) : null,
  );
  const {
    tracks: { search_tracks },
  } = newFacetedSearch;
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const onChange = useCallback(
    (internalState) => {
      setFacetedState(internalState);
    },
    [setFacetedState],
  );

  const handleChange = ({ OPERATION, category, LOCATION, OPERATION_SUBTYPE }) => {
    const withFilters = hasFilters();

    let isSecondaryFilterSelected;

    if (withFilters) {
      isSecondaryFilterSelected = getIsSecondaryFilterSelected();
    }

    if (withFilters && LOCATION !== '&!{}') {
      setLastFilterId(STATE);
    }

    if (layout_options?.current === LAYOUTS.TYPE_MAP && !isSecondaryFilterSelected) {
      onChange({ OPERATION, category, LOCATION, OPERATION_SUBTYPE });
      sendTrack(facetedState, search_tracks, true);
      trackClickEvent(null, track.melidata);
    }

    if (withFilters && isSecondaryFilterSelected) {
      setIsSecondaryFilterSelected(false);
    }

    const locationParsed = deserializeState({ LOCATION });

    return onPrimaryFilterChange({
      OPERATION,
      OPERATION_SUBTYPE: OPERATION_SUBTYPE ? facetedConfig.find((config) => config.id === OPST)?.values.true : null,
      PROPERTY_TYPE: category ? category.split('_')[1] : null,
      city: locationParsed?.city,
      state: locationParsed?.state,
      neighborhood: locationParsed?.neighborhood,
    });
  };

  return (
    <div className={NAMESPACE}>
      {isFacetedSearchVisible && facetedConfig && (
        <FacetedSearchDesktop
          initialState={initialState}
          renderConfig={renderConfig}
          onChange={!isLoading && handleChange}
        />
      )}
      <SecondaryFilters
        availableFilters={filters.available_filters.filters}
        availableLabels={filters.available_filters.labels}
        appliedFilters={filters.applied_filters.filters}
        appliedLabels={filters.applied_filters.labels}
        isLoading={isLoading}
        {...filters.applied_text}
      />
    </div>
  );
};

Filters.propTypes = {
  filters: shape({
    applied_filters: arrayOf(shape({})).isRequired,
    applied_labels: shape({}).isRequired,
    available_filters: arrayOf(shape({})).isRequired,
    available_labels: shape({}).isRequired,
  }),
  isLoading: bool,
  newFacetedSearch: shape({
    applied_filters: arrayOf(shape({})),
    faceted_config: arrayOf(shape({})),
  }),
  onPrimaryFilterChange: func.isRequired,
};

Filters.defaultProps = {
  filters: {},
  isLoading: false,
};

export default Filters;
