import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import constuctClassName from 'classnames';

import HighlightedFilter from './components/highlighted-desktop-content';
import { APPROVED_LOAN, DEFAULT_PROPS, FILTER_EXTENDED_PROPS, NAMESPACE } from './constants';
import { useSearch } from '../../../hooks/context';
import HighlightedFilterVis from '../../../components/sidebar/components/highlighted-filter-vis/highlighted-filter-vis';
import withComponentHandler from '../../../hocs/with-component-handler';

const { DEFAULT_INITIAL_EXPANDED_VALUE } = DEFAULT_PROPS;
/**
 * @typedef {Object} HighlightedFilterValue
 * @property {string} [id] - El identificador del valor del filtro.
 * @property {string} [name] - El nombre del valor del filtro.
 * @property {Object} [additionalProps] - Propiedades adicionales asociadas al valor del filtro.
 */

/**
 * @typedef {Object} HighlightedFilterContainerProps
 * @property {string} [className] - Las clases CSS adicionales para el componente.
 * @property {HighlightedFilterValue[]} expanded_values - Los valores expandidos asociados al filtro.
 * @property {string} [id] - El identificador del filtro.
 * @property {string} [name] - El nombre del filtro.
 */

/**
 * Componente que renderiza un contenedor para filtros destacados.
 *
 * @param {HighlightedFilterContainerProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el contenedor de filtros destacados.
 */
const HighlightedFilterContainer = ({
  expanded_values: initialExpandedValues = DEFAULT_INITIAL_EXPANDED_VALUE,
  className = '',
  id = '',
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <li
      className={constuctClassName(className, NAMESPACE, `${NAMESPACE}-${id}`, { 'shops__highlighted-lists': isShops })}
    >
      {initialExpandedValues.map((value) =>
        id === APPROVED_LOAN ? (
          <HighlightedFilterVis {...value} filterId={id} key={value.id} />
        ) : (
          <HighlightedFilter {...value} filterId={id} key={value.id} />
        ),
      )}
    </li>
  );
};

HighlightedFilterContainer.propTypes = {
  className: string,
  expanded_values: arrayOf(shape(FILTER_EXTENDED_PROPS)),
  id: string,
  name: string,
};

export default withComponentHandler(HighlightedFilterContainer, { componentName: 'HighlightedFilterContainer' });
