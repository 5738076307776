import { STYLE_MODIFIERS } from '../constants';
import { FILTERS } from '../../../../../constants';

const { IDS } = FILTERS;

const getModalModifier = (filterId) => {
  switch (filterId) {
    case IDS.VEHICLE_BODY_TYPE:
      return STYLE_MODIFIERS.VEHICLE_BODY_MODAL;

    default:
      return STYLE_MODIFIERS.DEFAULT_MODAL;
  }
};

export default getModalModifier;
