import { shape, string, objectOf, any, oneOf, bool } from 'prop-types';

import { LIST_LINK, MAP_LINK, DEFAULT } from './component';

const MapButtonDesktopProps = {
  analytics_track_event: objectOf(any),
  label: string.isRequired,
  melidata_track_value: objectOf(any),
  tooltip: objectOf(any),
  type: oneOf([LIST_LINK, MAP_LINK, DEFAULT]),
  url: string.isRequired,
};

const MapLinkDesktopProps = {
  analytics_track_event: objectOf(any),
  hasIcon: bool,
  label: string.isRequired,
  melidata_track_value: objectOf(any),
  tooltip: objectOf(any),
  url: string.isRequired,
};

const MapLinkMobileProps = {
  map_link: shape({
    analytics_track_event: shape({}),
    melidata_track_value: shape({}),
    label: string,
    url: string,
    tooltip: shape({}),
    type: oneOf([LIST_LINK, MAP_LINK]),
  }).isRequired,
};

export { MapButtonDesktopProps, MapLinkDesktopProps, MapLinkMobileProps };
