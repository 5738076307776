import React from 'react';

import { FilterLink } from '../../../../../../link';
import { valueWithLinkConfigPropTypes } from '../../../prop-types';

const ValueLinkWithIcon = ({ namespace, Icon, modifier, results, url, linkConfig, name, accessibilityText }) => {
  const ariaLabel = accessibilityText ?? name;

  const styleIcon = Icon.name === 'IconFull' ? { width: '40px' } : null;

  return (
    <FilterLink className={`${namespace}-icon`} href={url} {...linkConfig} ariaLabel={ariaLabel} title={ariaLabel}>
      <Icon className={`${namespace}-icon--${modifier}`} style={styleIcon} />
      {results && <span className={`${namespace}-results-qty`}>{results}</span>}
    </FilterLink>
  );
};

ValueLinkWithIcon.propTypes = {
  ...valueWithLinkConfigPropTypes,
};

export default ValueLinkWithIcon;
