import React from 'react';
import { bool, number, string } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../../../../hooks/context';
import { trackEvent } from '../../../../../lib/melidata';
import getIcon from '../../../../icons';
import { ListItemSchema } from '../constants';

const BreadcrumbItem = ({ isLast, position, text, url }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  const handleClick = () => {
    trackEvent({
      path: '/search/breadcrumb/select',
      event_data: { url },
      clean: true,
    });
  };

  return (
    <li
      className={classnames('andes-breadcrumb__item', { 'shops__breadcrumb-item': isShops })}
      itemProp="itemListElement"
      itemType={ListItemSchema}
      itemScope
    >
      <a onClick={handleClick} href={url} title={text} itemProp="item" className="andes-breadcrumb__link">
        <span itemProp="name">{text}</span>
      </a>
      <meta itemProp="position" content={position} />
      {!isLast && getIcon({ iconId: 'chevron', className: 'andes-breadcrumb__chevron ui-search-breadcrumb__chevron' })}
    </li>
  );
};

BreadcrumbItem.propTypes = {
  isLast: bool.isRequired,
  position: number.isRequired,
  text: string.isRequired,
  url: string.isRequired,
};

export default BreadcrumbItem;
