import React from 'react';
import { string } from 'prop-types';

import Button, { ButtonText } from '@andes/button';

import IconFilterToolbar from '../../../../../components-v2/icons/sources/toolbar-filter';
import { NAMESPACE } from '../constants';

const FiltersButton = ({ label, totalLabel }) => (
  <Button hierarchy="transparent" className={`${NAMESPACE}__filters-button`}>
    <IconFilterToolbar className={`${NAMESPACE}__filters-button--icon`} />
    <ButtonText>{label}</ButtonText>
    {totalLabel && (
      <ButtonText>
        <span className={`${NAMESPACE}__filters-button--text-highlight`}>{totalLabel}</span>
      </ButtonText>
    )}
  </Button>
);

FiltersButton.propTypes = {
  label: string.isRequired,
  totalLabel: string.isRequired,
};

export default FiltersButton;
