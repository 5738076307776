import React from 'react';
import { string, shape } from 'prop-types';

import classNames from 'classnames';
import { MoneyAmount } from '@andes/money-amount';

import { getFormattedIntFraction, getFormattedDecimalFraction } from '../../../utils/format-price';
import { PACKAGE_NAMESPACE_LABEL } from '../constants';

const NAMESPACE = `${PACKAGE_NAMESPACE_LABEL}-price__part`;
/**
 * @typedef {Object} PriceProps
 * @property {string} [className] - Clases CSS adicionales para el componente.
 * @property {string} [currencyId] - El identificador de la moneda.
 * @property {string} [decimal] - La parte decimal del precio.
 * @property {string} [id] - El identificador único del precio.
 * @property {string} [thousandSeparator] - El separador de miles para el precio.
 * @property {Object} [styles] - Los estilos en línea aplicados al componente.
 * @property {string} [text] - El texto que representa el precio.
 * @property {number} [size] - El tamaño de la fuente del precio.
 * @property {string} [symbol] - El símbolo de la moneda.
 * @property {string} [html_tag] - La etiqueta HTML que se usará para renderizar el precio (por ejemplo, 'div', 'span').
 */

/**
 * Componente que renderiza un precio con formato personalizado.
 *
 * @param {PriceProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el precio renderizado.
 */
const Price = ({
  className,
  currencyId,
  decimal,
  id,
  thousandSeparator = '.',
  styles,
  text,
  size = 16,
  symbol = '',
  html_tag: Tag = 'div',
}) => {
  const intFraction = getFormattedIntFraction(text, thousandSeparator);
  const decimalFraction = getFormattedDecimalFraction(decimal);

  return (
    <Tag className={classNames(NAMESPACE, className, id)} style={styles}>
      <MoneyAmount
        value={{
          decimal_separator: thousandSeparator,
          fraction: intFraction,
          cents: decimal ? decimalFraction : null,
        }}
        size={size}
        centsType="superscript"
        currencyId={currencyId}
        styles={styles}
        symbol={symbol}
      />
    </Tag>
  );
};

Price.propTypes = {
  className: string,
  currencyId: string,
  decimal: string,
  html_tag: string,
  id: string,
  size: string,
  styles: shape({}),
  symbol: string,
  text: string,
  thousandSeparator: string,
};

export default Price;
