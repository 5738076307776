const NAMESPACE = 'ui-search-bookmark';
const BOOKMARK_ICON_ID = 'bookmark';

const DEFAULT_PROPS = {
  DEFAULT_ITEM: {},
  DEFAULT_CLASSNAME: '',
};

const ICON_CLASSES = {
  bookmark: `${NAMESPACE}__icon-bookmark`,
  bookmarkFill: (bookmarkState) =>
    `${NAMESPACE}__icon-bookmark-fill${bookmarkState ? ` ${NAMESPACE}__icon-bookmark-fill--active` : ''}`,
};

export { NAMESPACE, BOOKMARK_ICON_ID, DEFAULT_PROPS, ICON_CLASSES };
