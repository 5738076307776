import { bool, node, number, string, shape, objectOf, any } from 'prop-types';

export const namespace = 'ui-search-tooltip-filter';

export const MOBILE_PROP_TYPES = {
  children: node.isRequired,
  className: string,
  mask: bool,
  maskClosable: bool,
  side: string,
  tooltip: shape({
    title: string,
    offset_x: number,
    offset_y: number,
    content: string,
    filter_content_id: string,
    max_print: number,
    side: string,
    tracks: objectOf(any),
  }),
};

export const DEFAULT_SIDE = 'bottom';

export const MOBILE_DEFAULT_PROPS = {
  className: '',
  mask: false,
  maskClosable: true,
  side: DEFAULT_SIDE,
  tooltip: {},
  tooltipStatus: {},
};

export const FILTER_TOOLTIP_PREFIX = 'filter-tooltip';

export const defaultPropTooltip = {
  side: 'right',
  type: 'highlight',
};
