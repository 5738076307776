import React from 'react';
import { string } from 'prop-types';

import { Head } from 'nordic/head';
import { ErrorScreen } from '@andes/technical-error';
import { ErrorUXScreen } from 'error-ux-components';

import { getMessagesErrorBySiteId } from '../../lib/get-message-error';
import IconErrorScreen from '../icons/sources/error-screen';
import ERROR_CONTEXT_PROPS_TYPES from '../../constants/propsTypes/errorCotext';

const ErrorComponent = ({ siteId, errorContext }) => {
  const { statusCode } = errorContext || {};
  const { title, description } = getMessagesErrorBySiteId(siteId, statusCode);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {errorContext ? (
        <ErrorUXScreen errorContext={errorContext} description={description} title={title} />
      ) : (
        <div className="ui-search ui-search-error">
          <ErrorScreen
            asset={<IconErrorScreen />}
            className="ui-search-error__screen"
            description={description}
            title={title}
          />
        </div>
      )}
    </>
  );
};

ErrorComponent.propTypes = {
  errorContext: ERROR_CONTEXT_PROPS_TYPES,
  siteId: string.isRequired,
};

export default ErrorComponent;
