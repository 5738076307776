import React from 'react';
import { bool } from 'prop-types';

import getIcon from '../../icons';
import { BOOKMARK_ICON_ID, ICON_CLASSES } from '../constans';

const BookmarkIconComponent = ({ bookmarkState }) => (
  <>
    {getIcon({ iconId: BOOKMARK_ICON_ID, className: ICON_CLASSES.bookmark })}
    {getIcon({ iconId: BOOKMARK_ICON_ID, className: ICON_CLASSES.bookmarkFill(bookmarkState) })}
  </>
);

BookmarkIconComponent.propTypes = {
  bookmarkState: bool,
};

export default BookmarkIconComponent;
