import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--alert-edit';
const ICON_ID = 'ic_alert_edit';

const IconAlertEdit = (props) => (
  <BuildIcon
    {...props}
    dataTestId="edit_alert_icon"
    className={classnames(namespace, props.className)}
    viewBox="0 0 32 32"
    iconId={ICON_ID}
  />
);

IconAlertEdit.propTypes = {
  className: string,
};

export default IconAlertEdit;
