import React from 'react';
import { arrayOf, bool, string } from 'prop-types';

import classnames from 'classnames';
import { MoneyAmount } from '@andes/money-amount';

import getIcon from './get-icon';

const namespace = 'ui-search-icon-label';

const ICON_FORMAT_REGEX = /\{icon\}/gi;
const PRICE_FORMAT_REGEX = /\{price\}/gi;
const ICON_AND_PRICE_FORMAT_REGEX = /\{icon\}|\{price\}/gi;

const IconLabel = ({
  icons: initialIcons,
  className = '',
  text,
  htmlForIDValue = '',
  hasHtml = false,
  srLabel,
  Tag = 'label',
  currencyId,
  price,
  htmlId,
}) => {
  const icons = initialIcons || [];
  const labelClassName = classnames(className, namespace);

  if (hasHtml) {
    // eslint-disable-next-line react/no-danger
    return <span htmlFor={htmlForIDValue} className={labelClassName} dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const iconsMatches = text?.match(ICON_FORMAT_REGEX);
  const priceMatches = text?.match(PRICE_FORMAT_REGEX);

  if (!icons || (!iconsMatches && !priceMatches) || iconsMatches?.length < icons?.length) {
    return (
      <Tag htmlFor={htmlForIDValue} className={labelClassName} id={htmlId}>
        {text}
      </Tag>
    );
  }

  const textParts = text.trim() === '{icon}' ? [''] : text.split(ICON_AND_PRICE_FORMAT_REGEX);

  return (
    <Tag htmlFor={htmlForIDValue} className={labelClassName} key={htmlForIDValue} id={htmlId}>
      {textParts.map((textPart, index) => {
        const renderIconLeft = !textPart && index < icons?.length;
        const renderIconRight = !textPart && index === icons?.length;
        const renderPrice = !textPart && priceMatches?.length > 0;

        return (
          <span key={textPart}>
            {textPart}
            {renderIconLeft && getIcon({ iconId: icons[index], description: srLabel })}
            {renderIconRight && getIcon({ iconId: icons[index - 1], description: srLabel })}
            {renderPrice && (
              <MoneyAmount
                size={12}
                currencyId={currencyId}
                value={{
                  cents: '',
                  fraction: price,
                }}
              />
            )}
          </span>
        );
      })}
    </Tag>
  );
};

IconLabel.propTypes = {
  className: string,
  currencyId: string,
  hasHtml: bool,
  htmlForIDValue: string,
  htmlId: string,
  icons: arrayOf(),
  price: string,
  srLabel: string,
  Tag: string,
  text: string.isRequired,
};

export default IconLabel;
