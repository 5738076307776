import React from 'react';
import { shape, arrayOf, string, oneOf, node, oneOfType, number, bool } from 'prop-types';

import classnames from 'classnames';

import ComposedLabelAlternatives from './composed-label-alternative';
import Link from '../../components-v2/link';
import { GRID } from '../layout/renderer/constants';
import IconChevronRight from '../../components-v2/icons/sources/icon-chevron-right';

const namespace = 'ui-search-winner-alternatives';
const renderLabel = ({ componentId, componentIndex, itemId, alternative }) => {
  switch (componentId) {
    case 'TEXT':
    case 'PRICE': {
      return <ComposedLabelAlternatives itemId={itemId} alternative={alternative} componentIndex={componentIndex} />;
    }

    default:
      return null;
  }
};

const WithBorderType = ({ type, children, layout }) => {
  const classname = classnames(
    { [`${namespace}--${type}`]: layout !== GRID },
    { [`${namespace}--${type}--grid`]: layout === GRID },
  );

  if (type === 'chevron') {
    return (
      <div className={classname}>
        {' '}
        <div>{children}</div> <IconChevronRight />{' '}
      </div>
    );
  }

  return <div className={classname}> {children}</div>;
};

WithBorderType.propTypes = {
  children: node.isRequired,
  layout: string,
  type: string,
};

const WinnerAlternatives = ({ className, itemId, alternativeProp, alternatives, layout }) => (
  <ul className={classnames(namespace, className)}>
    {alternatives?.map((alternative) => {
      let children = (
        <WithBorderType type={alternative.type} layout={layout}>
          {alternative.components.map((component, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${component.id}_${i}`}>
              {renderLabel({
                componentId: component.id,
                componentIndex: i,
                itemId,
                alternative: { id: alternative.id, prop: alternativeProp },
              })}
            </React.Fragment>
          ))}
        </WithBorderType>
      );

      if (alternative.permalink) {
        children = (
          <Link href={alternative.permalink} title="winner alterantive">
            {children}
          </Link>
        );
      }

      return (
        <li key={alternative.id} className={`${namespace}__item`}>
          {children}
        </li>
      );
    })}
  </ul>
);

WinnerAlternatives.propTypes = {
  alternatives: arrayOf(
    shape({
      id: string,
      type: oneOf(['sandwich', 'chevron', 'borderless', 'border-blue', 'border-grey', 'separator-top']).isRequired,
      permalink: string,
      components: arrayOf(
        shape({
          id: string,
          label: shape({
            text: string.isRequired,
            color: string,
            font_weight: string,
            font_size: oneOfType([oneOf(['X_SMALL', 'XX_SMALL', 'XXX_SMALL']), number]),
            margin: shape({
              bottom: number,
              top: number,
            }),
            values: arrayOf(
              shape({
                type: oneOf(['price', 'text', 'icon']),
                key: string,
                amount: number,
                previous: bool,
                font_size: oneOfType([string, number]),
                id: string,
                font_weight: string,
              }),
            ),
          }),
        }),
      ),
    }),
  ),
  className: string,
  layout: string.isRequired,
};

export default WinnerAlternatives;
