import React from 'react';
import { string } from 'prop-types';

import { BuildIcon } from '../utils';

const ICON_ID = 'ic_search_bookmark_created';

const SearchBookmarkCreatedIcon = (props) => (
  <BuildIcon {...props} viewBox="0 0 12 16" iconId={ICON_ID} color={props.color ?? '#3483FA'} width="12" height="16" />
);

SearchBookmarkCreatedIcon.propTypes = {
  className: string,
  color: string,
  style: string,
};

export default SearchBookmarkCreatedIcon;
