import React from 'react';

import { useStaticProps } from '../../components/context/static-props';
import { useLayout } from '../../hooks/context';
import { linkBuildProps, linkBuildNewHref } from './utils';
import { NO_INDEX_PARAM, namespace } from './constants';
import { linkPropTypes } from './propTypes';
import withComponentHandler from '../../hocs/with-component-handler';

/**
 * @typedef {Object} LinkProps
 * @property {string} ariaLabel - The aria-label attribute for accessibility.
 * @property {React.ReactNode} children - The content to be displayed inside the link.
 * @property {string} className - The CSS class name for the link.
 * @property {string} href - The URL the link points to.
 * @property {boolean} isInternal - Indicates if the link is internal.
 * @property {boolean} keepLayout - Indicates if the layout should be kept.
 * @property {string} role - The role attribute for accessibility.
 * @property {string} tabIndex - The tabIndex attribute for keyboard navigation.
 * @property {string} title - The title attribute for the link.
 * @property {string} linkTag - The tag to be used for the link.
 * @property {string} target - The target attribute specifying where to open the linked document.
 * @property {Object} tracks - Tracking information for analytics and melidata.
 * @property {Object} tracks.analyticsTrack - Analytics tracking information.
 * @property {Object} tracks.melidataTrack - Melidata tracking information.
 * @property {function} onClick - The function to be called when the link is clicked.
 * @property {string} filterId - The filter ID associated with the link.
 */

/**
 * Link component to render a hyperlink with various properties.
 *
 * @param {LinkProps} props - The properties for the Link component.
 * @returns {JSX.Element} The rendered Link component.
 */

const shouldAddNoFollow = (href) => href && href.indexOf(NO_INDEX_PARAM) !== -1;

const Link = ({
  className = '',
  href = '',
  linkTag = 'a',
  title = null,
  role = null,
  tabIndex = null,
  onClick = null,
  isInternal = true,
  keepLayout = true,
  ariaLabel = null,
  ariaLabelledBy = null,
  tracks = null,
  target = null,
  children,
  isAd,
  adLabel,
}) => {
  const { queryParams } = useStaticProps();
  const { currentLayout, initialLayout } = useLayout();

  const newHref = linkBuildNewHref({ href, isInternal, queryParams, keepLayout, currentLayout, initialLayout });
  const addNoFollow = shouldAddNoFollow(href) || adLabel;
  const linkProps = linkBuildProps({
    linkTag,
    newHref,
    href,
    className,
    namespace,
    title,
    role,
    tabIndex,
    onClick,
    target,
    ariaLabel,
    ariaLabelledBy,
    tracks,
    shouldAddNoFollow: addNoFollow,
    isAd,
  });

  return React.createElement(linkTag, { ...linkProps }, children);
};

Link.propTypes = {
  ...linkPropTypes,
};

export default withComponentHandler(Link, { componentName: 'Link' });
