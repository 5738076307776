import React from 'react';

import { loadable } from 'nordic/lazy';

import useCarousel from '../../components/carousel-search/use-carousel.mobile';

const ChunkCarousel = () => {
  const carousel = useCarousel();
  const CarouselSearch = loadable(async () => import(/* webpackChunkName: "chunk-carousel" */ './carousel.desktop'));

  if (!carousel) {
    return null;
  }

  return (
    <CarouselSearch
      items={carousel?.values}
      type={carousel?.type}
      filters={carousel?.filters}
      polycard_context={carousel?.polycard_context}
    />
  );
};

export default ChunkCarousel;
