import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';

import ProgressIndicatorCircular from '@andes/progress-indicator-circular';

import { AppliedFiltersContainer } from '../../../../filters/applied/applied-filters.desktop';
import AvailableFilters from '../../../../../components-v2/sidebar/components/filters/index.desktop';

const FiltersContent = ({ isLoading, appliedFilters, availableFilters, availableLabels }) => (
  <>
    {isLoading && <ProgressIndicatorCircular modifier="block" size="large" />}
    <AppliedFiltersContainer filters={appliedFilters} />
    <AvailableFilters filters={availableFilters} labels={availableLabels} />
  </>
);

FiltersContent.propTypes = {
  appliedFilters: arrayOf(shape({})).isRequired,
  availableFilters: arrayOf(shape({})).isRequired,
  availableLabels: shape({}).isRequired,
  isLoading: bool,
};

FiltersContent.defaultProps = {
  isLoading: false,
};

export default FiltersContent;
