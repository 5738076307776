import React from 'react';
import { string, shape, number } from 'prop-types';

import classnames from 'classnames';

import { getStyleProps } from '../../../lib/style-props';
import StyledLabelWrapper from '../../styled-label/styled-label';
import ComposedLabel from '../../composed-label/composed-label';
import getIcon from '../../../components-v2/icons';

const namespace = 'ui-search-item__highlight-label';

const renderComponent = (label, secondary_label, htmlForIDValue) => {
  const { values } = label;

  if (values) {
    return (
      <div>
        <ComposedLabel className={`${namespace}--composed-title`} {...label} />
        {secondary_label && <StyledLabelWrapper className={`${namespace}--secondary-label`} {...secondary_label} />}
      </div>
    );
  }

  return <StyledLabelWrapper className={`${namespace}__text`} htmlForIDValue={htmlForIDValue} {...label} />;
};

const renderSuffixText = (suffix_text, htmlForIDValue) => (
  <StyledLabelWrapper htmlForIDValue={htmlForIDValue} className={`${namespace}--secondary-label`} {...suffix_text} />
);

const HighlightLabel = ({
  className = null,
  type = null,
  label = null,
  secondary_label = null,
  htmlForIDValue = '',
  icon: initialIcon,
  suffix_text = null,
  ...props
}) => {
  const icon = initialIcon || { highlight_position: 'left' };
  const highlight_position = icon?.highlight_position || 'left';

  return (
    <div
      className={classnames(
        namespace,
        className,
        { [`${namespace}--${type}`]: !!type },
        { [`${namespace}--no-background`]: !props.background },
      )}
      style={getStyleProps(props)}
    >
      <div className={`${namespace}__container`}>
        {icon && highlight_position === 'left' && getIcon({ iconId: icon.id, color: icon.color })}
        {label && renderComponent(label, secondary_label, htmlForIDValue)}
        {suffix_text && renderSuffixText(suffix_text, htmlForIDValue)}
        {icon && highlight_position === 'right' && getIcon({ iconId: icon.id, color: icon.color })}
      </div>
    </div>
  );
};

HighlightLabel.propTypes = {
  background: string,
  className: string,
  htmlForIDValue: string,
  icon: shape({
    id: string.isRequired,
    color: string,
    highlight_position: string,
  }),
  label: shape({
    text: string.isRequired,
    color: string,
    background: string,
    size: number,
    weight: number,
    style: string,
  }),
  secondary_label: shape({
    text: string.isRequired,
  }),
  suffix_text: shape({
    text: string.isRequired,
  }),
  type: string,
};

export default HighlightLabel;
