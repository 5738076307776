import React from 'react';

import classnames from 'classnames';

import RenderValueLink from './value-link';
import { objValuesPropTypes } from '../prop-types';
import { STYLE_MODIFIERS } from '../constants';
import { FILTERS } from '../../../../../constants';

const namespace = 'ui-search-filter';

const RenderValues = ({ id, name, results, url, tooltip, tracks, accessibility_text, isShops, filterId }) => {
  const { IDS } = FILTERS;

  return (
    <li key={id} className={classnames(`${namespace}-container`, { 'shops__container-lists': isShops })}>
      <RenderValueLink
        namespace={namespace}
        id={id}
        name={name}
        results={results}
        url={url}
        tooltip={tooltip}
        filterId={filterId}
        tracks={tracks}
        ids={IDS}
        modifier={STYLE_MODIFIERS.FULL_ICON}
        accessibilityText={accessibility_text}
      />
    </li>
  );
};

RenderValues.propTypes = {
  ...objValuesPropTypes,
};

export default RenderValues;
