import classnames from 'classnames';
import { CardHeader } from '@andes/card';

import { namespace } from '../constants';
import InfoImageTextBoxIntervention from '../../../components-v2/intervention/seo-info-image-text-box-intervention/seo-info-image-text-box-intervention.desktop';
import { seoInfoImageTextBoxInterventionPropTypes } from '../../../components-v2/intervention/seo-info-image-text-box-intervention/constants';

const SeoInfoImageTextBoxInterventionDesktop = ({
  title,
  description,
  alt_image,
  image_url,
  expansion_text,
  contraction_text,
}) => (
  <CardHeader className={classnames(`${namespace}__header`)}>
    <InfoImageTextBoxIntervention
      title={title}
      description={description}
      alt_image={alt_image}
      image_url={image_url}
      expansion_text={expansion_text}
      contraction_text={contraction_text}
    />
  </CardHeader>
);

SeoInfoImageTextBoxInterventionDesktop.propTypes = seoInfoImageTextBoxInterventionPropTypes;

export default SeoInfoImageTextBoxInterventionDesktop;
