import React from 'react';
import { string, bool, node } from 'prop-types';

import classNames from 'classnames';

/**
 * @typedef {Object} HighlightedWrapperProps
 * @property {string} [namespace] - El espacio de nombres CSS para el componente.
 * @property {boolean} [isShops] - Indica si el filtro pertenece a la vista de tiendas.
 * @property {boolean} [isPageLoaded] - Indica si la página ya se ha cargado.
 * @property {string} [url] - La URL asociada al filtro.
 * @property {React.ReactNode} [children] - Los componentes hijos que serán renderizados.
 */

const HighlightedWrapper = ({ namespace, isShops, isPageLoaded, url, children }) => (
  <div
    className={classNames(`${namespace}__container`, {
      'shops__filter-highlighted': isShops,
    })}
  >
    {isPageLoaded ? children : <a href={url}>{children}</a>}
  </div>
);

HighlightedWrapper.propTypes = {
  children: node,
  isPageLoaded: bool,
  isShops: bool,
  namespace: string,
  url: string,
};

export default HighlightedWrapper;
