import React from 'react';

import { SWITCH_FILTERS_LABEL_PROPS_TYPES } from '../constants/prop-types';
import LabelBuilderWrapper from './label-builder-wrapper';

const SwitchFilterLabel = ({ title, subtitle, highlight, label }) => (
  <>
    <LabelBuilderWrapper labelProps={title} labelBuilderProps={title?.titleLabelBuilderProps} />
    <LabelBuilderWrapper labelProps={subtitle} labelBuilderProps={subtitle?.subtitleLabelBuilderProps} />
    <LabelBuilderWrapper labelProps={highlight} labelBuilderProps={highlight?.highlightLabelBuilderProps} />
    <LabelBuilderWrapper labelProps={label} labelBuilderProps={label?.labelLabelBuilderProps} />
  </>
);

SwitchFilterLabel.propTypes = {
  ...SWITCH_FILTERS_LABEL_PROPS_TYPES,
};

export default SwitchFilterLabel;
