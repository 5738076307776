import { LABEL_COMPONENTS } from '../constants';
import { ASSETS } from '../../../constants';

const { ICON, DEFAULT, PRICE, LABEL } = LABEL_COMPONENTS;

const builHighlightedLabelText = (values) => {
  const properties = [
    { key: PRICE.name, value: PRICE.text },
    { key: ICON.name, value: ICON.text },
    { key: DEFAULT.name, value: DEFAULT.text, iconPosition: values?.text?.icon_position },
    { key: LABEL.name, value: LABEL.text },
  ];

  return properties
    .reduce((parts, { key, value, iconPosition }) => {
      if (values[key]) {
        if (key === DEFAULT.name && iconPosition === ASSETS.POSITION.RIGHT) {
          parts.unshift(value);
        } else if (
          (key === DEFAULT.name && !values[DEFAULT.name].processed_text) ||
          (key === LABEL.name && !values[LABEL.name].text?.processed_text) ||
          (key === ICON.name && values.icons?.length < 1)
        ) {
          return parts;
        } else {
          parts.push(value);
        }
      }

      return parts;
    }, [])
    .join(' ');
};

export default builHighlightedLabelText;
