import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIconImage } from '../utils';

const IconMelicoin = (props) => {
  const prefixClass = props.prefixClass ?? 'ui-search';

  const namespace = `${prefixClass}-icon ${prefixClass}-icon--icon-melicoin`;

  const centsTypeStyles = props.centsType === 'dot' ? '2px' : '';

  return (
    <div className={classnames(namespace, props.className)} style={{ marginTop: centsTypeStyles }}>
      <BuildIconImage {...props} src="icon-melicoin.svg" description={props.description ?? 'Meli icon'} />
    </div>
  );
};

IconMelicoin.propTypes = {
  centsType: string,
  className: string,
  description: string,
  prefixClass: string,
};

export default IconMelicoin;
