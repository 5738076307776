import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import cx from 'classnames';
import { Modal as AndesModal } from '@andes/modal';
import { Switch } from '@andes/switch';

import useFilterSearch from '../../../../hooks/use-filter-search';
import IconLabel from '../../../../components-v2/icons/utils/icon-label'; // TODO: check if we can be removed this component
import IconQuestion from '../../../../components-v2/icons/sources/question';
import { useStaticProps } from '../../../context/static-props';
import { getLayout } from '../../../context/layout';
import useMapConfig from '../../../map/hooks/use-map-config';
import { useSearch } from '../../../../hooks/context';
import { LAYOUTS } from '../../../../constants';
import { trackClickEvent } from '../../../map/track';
import FilterTooltipContainer from '../../../../components-v2/sidebar/components/filters-tooltip/container.mobile';
import { linkBuildNewHref } from '../../../../components-v2/link';
import { trackEvent } from '../../../../lib/melidata';
import processTextWithAssets from '../../../../utils/process-text-with-assets';
import SwitchFilterLabel from '../../../../components-v2/switch-filter/components/switch-filter-label';
import getLabelBuilderProps from '../../../../components-v2/switch-filter/helper/get-label-builder-props';
import { SplitHelperText } from '../../../../components-v2/sidebar/components/highlighted-filter/helper/split-helper-text';

const namespace = 'ui-search-filter-highlighted-vis';

const HighlightedFilterVis = ({ filterId, subtitle, switch: { is_on: isOn }, title, tooltip, url, highlight }) => {
  const { queryParams } = useStaticProps();
  const filterSearch = useFilterSearch(url, filterId);
  const isClientSideFilter = !!filterSearch;
  const [isChecked, setIsChecked] = useState(isOn);
  const { layout_options } = useSearch();
  const { searchTrack } = useMapConfig();
  const [showModal, setShowModal] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(true);
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const { tracks } = tooltip;

  const onClickTooltip = () => {
    if (tracks?.cta) {
      trackEvent({ clean: true, ...tracks.cta });
    }

    setShowTooltip(!showTooltip);
  };

  const handleClick = (e) => {
    if (e.type !== 'change') {
      e.preventDefault();
      setIsChecked(!isOn);
    }

    if (isClientSideFilter) {
      filterSearch();

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      return;
    }

    const { currentLayout } = getLayout();

    window.location.href = linkBuildNewHref({ href: url, queryParams, isInternal: true, currentLayout });
  };

  const handleQuestionClick = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  let actions = [];

  if (tooltip.actions[0]) {
    actions = [{ ...tooltip.actions[0], isPermanentClosed: true, onClick: onClickTooltip }];
  }

  const tooltipData = {
    ...tooltip,
    actions,
    side: 'right',
    offset_x: 0,
    offset_y: 45,
    filter_content_id: 'APPROVED_CREDIT_LINE_TOOLTIP',
    max_print: 80,
  };

  const titleLabelBuilderProps = getLabelBuilderProps({
    ...title,
    text: processTextWithAssets(title?.text),
  });

  const sanitizedVISProps = SplitHelperText(titleLabelBuilderProps);

  const subtitleLabelBuilderProps = getLabelBuilderProps({
    ...subtitle,
    text: processTextWithAssets(subtitle?.text),
  });

  return (
    <>
      <form action={url} onSubmit={handleClick} className={cx(`${namespace}__container`)}>
        <button name="button" type="submit" className={cx(`${namespace}__content ${namespace}__content-button`)}>
          <div className={`${namespace}__container-vertical`}>
            <div className={`${namespace}__icons-container`}>
              <IconLabel className={`${namespace}__icon-label`} {...highlight.pill} />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={cx(`${namespace}__tooltip`)} onClick={handleQuestionClick}>
                <IconQuestion />
              </div>
            </div>
            <SwitchFilterLabel
              namespace={namespace}
              hasHtml
              htmlForIDValue={filterId}
              title={{
                ...title,
                titleLabelBuilderProps: sanitizedVISProps,
                className: `${namespace}__label-title-fade`,
              }}
              subtitle={{
                ...subtitle,
                subtitleLabelBuilderProps,
                className: `${namespace}__label-subtitle-fade`,
              }}
            />
          </div>
        </button>
        <div className={cx(`${namespace}__switch-container`)}>
          <div className={cx(`${namespace}__switch-wrapper-vis`)}>
            {showTooltip && <FilterTooltipContainer tooltip={tooltipData} side="left" />}
            <Switch
              id={filterId}
              className={`${namespace}__switch-icon ${namespace}__tooltip-trigger`}
              label=""
              checked={isChecked}
              autoComplete="off"
              onChange={handleClick}
            />
          </div>
        </div>
      </form>
      {showModal && (
        <AndesModal
          title={highlight.help_button?.modal_title}
          className={`${namespace}-modal`}
          closeButtonSrLabel="Cerrar"
          open={showModal}
          onClose={() => setShowModal(false)}
          data-testid="top-switch-filter-modal"
        >
          <iframe
            style={{ width: '100%', height: '100%' }}
            id="modalIframe"
            title="Modal Iframe"
            scrolling="no"
            src={highlight.help_button?.url}
          />
        </AndesModal>
      )}
    </>
  );
};

HighlightedFilterVis.propTypes = {
  actions: arrayOf(
    shape({
      type: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  filterId: string,
  highlight: shape({
    pill: shape({
      text: string,
      icon: arrayOf(string),
    }),
    help_button: shape({
      modal_title: string,
      url: string,
    }),
  }),
  subtitle: shape({
    text: string,
    icon: arrayOf(string),
  }),
  switch: shape({}),
  title: shape({
    text: string,
    icon: arrayOf(string),
  }),
  tooltip: shape({
    title: string,
    content: string,
    url: string,
  }),
  url: string,
};

export default HighlightedFilterVis;
