import React from 'react';

import classnames from 'classnames';

import { useSearch } from '../../hooks/context';
import { useUser } from '../../components/context/user';
import ComponentList from '../../utils/component-list';
import SeoContents from '../../components/seo-contents/seo-contents';
import withComponentHandler from '../../hocs/with-component-handler';

const availableComponents = {
  SEO_CONTENT_INTERVENTION: SeoContents,
};

const namespace = 'ui-search-footer-components';

/**
 * @typedef {Object} FooterComponent
 * @property {string} id - El identificador del componente del footer.
 * @property {string} type - El tipo del componente del footer.
 * @property {Object} props - Las propiedades específicas del componente.
 */

/**
 * Componente que renderiza los componentes del footer dinámicamente.
 *
 * @returns {React.ReactNode|null} Un nodo React que contiene los componentes del footer o `null` si no hay componentes disponibles.
 */
const FooterComponents = () => {
  const { footer_components } = useSearch();
  const { loggedIn } = useUser();
  const showFooterComponents = footer_components && footer_components.length > 0;

  if (!showFooterComponents) {
    return null;
  }

  return (
    <div className={classnames(namespace, { 'logged-in': loggedIn })}>
      <div className={classnames(`${namespace}__wrapper`)}>
        <div className={classnames(`${namespace}__container`)}>
          <ComponentList availableComponents={availableComponents} components={footer_components} />
        </div>
      </div>
    </div>
  );
};

export default withComponentHandler(FooterComponents, { componentName: 'FooterComponents' });
