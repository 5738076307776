import { loadable } from 'nordic/lazy';

const HEADER_TYPES = {
  OFFICIAL_STORE: 'OFFICIAL_STORE_HEADER',
  EXHIBITOR: 'EXHIBITOR',
  DEAL: 'BANNER',
  SELLER: 'SELLER',
  ESHOP: 'ESHOP',
  SHOWCASE: 'SHOWCASE',
};

const DEVICE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

const getHeaderComponent = (headerProps, deviceType) => {
  const headerComponents = {
    [HEADER_TYPES.DEAL]: loadable(async () => import('../deal')),
    [HEADER_TYPES.EXHIBITOR]: loadable(async () => import('../exhibitor/desktop')),
    [HEADER_TYPES.OFFICIAL_STORE]: {
      [DEVICE.MOBILE]: loadable(async () => import('../official-store/mobile')),
      [DEVICE.DESKTOP]: loadable(async () => import('../official-store/desktop')),
    },
    [HEADER_TYPES.SELLER]: {
      [HEADER_TYPES.ESHOP]: loadable(async () => import('../eshop')),
      [HEADER_TYPES.SHOWCASE]: loadable(async () => import('../showcase')),
    },
  };

  let dynamicRute = headerComponents[headerProps.type];

  if (headerProps.type === HEADER_TYPES.SELLER) {
    dynamicRute = dynamicRute[headerProps?.sub_type] || null;
  }

  if (headerProps.type === HEADER_TYPES.OFFICIAL_STORE) {
    dynamicRute = dynamicRute[deviceType];
  }

  return dynamicRute || null;
};

export { HEADER_TYPES, getHeaderComponent };
