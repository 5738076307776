const MLA = 'MLA';
const MLB = 'MLB';
const MLM = 'MLM';
/**
 * Generic message errors used only when we can't comunicate with the backend
 * we must review if we follow this path or if we use i18n capabilities instead
 */

const getMessagesErrorBySiteId = (siteId, statusCode = null) => {
  if (statusCode && statusCode === 404) {
    switch (siteId) {
      case MLA:
        return {
          title: 'Parece que esta página no existe',
          description: 'Ir a la página principal',
        };
      case MLB:
        return {
          title: 'Parece que essa página não existe',
          description: 'Ir para a página principal',
        };
      case MLM:
        return {
          title: 'Parece que esta página no existe',
          description: 'Ir a la página principal',
        };

      default:
        return {
          title: 'Parece que esta página no existe',
          description: 'Ir a la página principal',
        };
    }
  }

  switch (siteId) {
    case MLA:
      return {
        title: '¡Ups! hubo un error',
        description: 'Inténtalo más tarde',
      };
    case MLB:
      return {
        title: 'Ops! Ocorreu um erro',
        description: 'Tente novamente mais tarde',
      };
    case MLM:
      return {
        title: '¡Ups! hubo un error',
        description: 'Inténtalo más tarde',
      };

    default:
      return {
        title: '¡Ups! hubo un error',
        description: 'Inténtalo más tarde',
      };
  }
};

export { getMessagesErrorBySiteId };
