import React from 'react';

import RenderFilterRange from './filter-range';
import { STYLE_MODIFIERS } from '../../constants';
import { FILTERS } from '../../../../../../constants';
import { objRangeContent } from '../../prop-types';

const { TYPES, IDS } = FILTERS;

const RangeContent = ({ id, values, url_templates, type, activeCurrencyId, labels, fragment }) => {
  if (url_templates && (type === TYPES.RANGE || type === TYPES.PRICE_WITH_CONVERSION)) {
    return id === IDS.VEHICLE_YEAR ? (
      <li>
        <RenderFilterRange
          values={values}
          url_templates={url_templates}
          labels={labels}
          type="dropdown"
          modifier={STYLE_MODIFIERS.YEAR_RANGE}
          fragment={fragment}
        />
      </li>
    ) : (
      <li>
        <RenderFilterRange
          values={values}
          url_templates={url_templates}
          labels={labels}
          modifier={id}
          activeCurrencyId={activeCurrencyId}
          type="input"
          fragment={fragment}
        />
      </li>
    );
  }

  return null;
};

RangeContent.propTypes = {
  ...objRangeContent,
};

export default RangeContent;
