import React, { useMemo } from 'react';

import getLabelBuilderProps from '../helper/get-label-builder-props';

const useLabelBuilderProps = ({ title, subtitle, highlight, label }) => {
  const titleLabelBuilderProps = useMemo(() => getLabelBuilderProps(title), [title]);
  const subtitleLabelBuilderProps = useMemo(() => getLabelBuilderProps(subtitle), [subtitle]);
  const highlightLabelBuilderProps = useMemo(() => getLabelBuilderProps(highlight), [highlight]);
  const labelLabelBuilderProps = useMemo(() => getLabelBuilderProps(label), [label]);

  return {
    titleLabelBuilderProps,
    subtitleLabelBuilderProps,
    highlightLabelBuilderProps,
    labelLabelBuilderProps,
  };
};

export default useLabelBuilderProps;
