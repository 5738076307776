import React from 'react';
import { string, shape } from 'prop-types';

import IconCoupon from '../../components-v2/icons/sources/icon-coupons';
import Promotions from '../card/promotions';
import { useStaticProps } from '../context/static-props';
import { STACK } from '../layout/renderer/constants';

const Pill = ({ element = null, layout = '' }) => {
  const { deviceType } = useStaticProps();

  let deviceSize = 155;

  let desktopSize = 210;

  if (typeof window !== 'undefined') {
    deviceSize = window.innerWidth > 377 ? 155 : 150;
  }

  if (layout === STACK) {
    desktopSize = 335;
  }

  const { promotion_type, label, currency, background, color } = element;

  const calculateContentWidth = {
    desktop: desktopSize,
    mobile: deviceSize,
  };

  const getIconComponent = {
    coupon: IconCoupon,
  };

  const getContentWitdh = calculateContentWidth[deviceType];
  const promotionIcon = getIconComponent[promotion_type] || null;

  return (
    <Promotions
      label={label}
      currency={currency}
      deviceType={deviceType}
      background={background}
      color={color}
      contentWidth={getContentWitdh}
      promotionIcon={promotionIcon}
    />
  );
};

Pill.propTypes = {
  element: shape({}),
  layout: string,
};

export default Pill;
