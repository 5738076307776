import React from 'react';

import ItemHighlight from '../../../../card/highlight/highlight-label';
import Group from '../../../../card/group/group';
import { CARD_DATA_GROUPS, GRID, STACK } from '../../constants';
import {
  renderTitle,
  renderOfficialStore,
  renderPriceGroup,
  renderGoodPrice,
  renderAttributesGroup,
  ContentSkeleton,
  renderPillBuilder,
  renderPillBottomBuilder,
  renderHeadlinePill,
} from '../common';

const renderTitleGroup = (item, layout) => (
  <Group noSeparator name={CARD_DATA_GROUPS.TITLE}>
    {renderTitle(item.title, layout, item.permalink, item.target, item.is_ad, item.title_compats)}
  </Group>
);

const renderLocationGroup = (item, options) => {
  const itemLocation = item.location || item.verticalInfo?.location;

  return (
    itemLocation && (
      <Group noSeparator name={CARD_DATA_GROUPS.LOCATION}>
        <span className="ui-search-item__location">{itemLocation}</span>
        {renderOfficialStore(item.vertical, item.official_store, options)}
      </Group>
    )
  );
};

const renderHighlightInfo = (item) => {
  const itemHighlightInfo = item.item_highlight || item.highlight || item.verticalInfo?.item_highlight;

  return itemHighlightInfo && <ItemHighlight {...itemHighlightInfo} />;
};

function renderContent(item, options, layout) {
  switch (layout) {
    case GRID:
      return (
        <ContentSkeleton
          topComponents={[
            renderHighlightInfo(item),
            renderHeadlinePill(item),
            renderPriceGroup(item, options),
            renderAttributesGroup(item),
            renderGoodPrice(item),
            renderPillBuilder(item),
            renderTitleGroup(item, layout),
            renderPillBottomBuilder(item),
            renderLocationGroup(item, options),
          ]}
        />
      );
    case STACK:

    // falls through
    default:
      return (
        <ContentSkeleton
          topComponents={[
            renderTitleGroup(item, layout),
            renderHighlightInfo(item),
            renderHeadlinePill(item),
            renderPriceGroup(item, options),
            renderPillBuilder(item),
            renderPillBottomBuilder(item),
          ]}
          leftColComponents={[renderAttributesGroup(item), renderLocationGroup(item, options)]}
        />
      );
  }
}

export default renderContent;
