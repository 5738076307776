import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import StyledLabelWrapper from '../styled-label/styled-label';
import getIcon from '../../components-v2/icons';
import { BuildIconImage } from '../../components-v2/icons/utils';

const namespace = 'ui-search-composed-label-value';

const TYPES = {
  TEXT: 'text',
  BOLD_TEXT: 'bold_text',
  ICON: 'icon',
};

const renderIcon = ({ id, ...props }) => {
  if (id === 'url-icon') {
    return <BuildIconImage iconId={id} description={props?.alt} {...props} />;
  }

  return getIcon({ iconId: id, ...props });
};

const ComposedLabelValue = ({ type, className, ...props }) => {
  const valueClassName = classnames(className, namespace);

  switch (type) {
    case TYPES.TEXT:
    case TYPES.BOLD_TEXT:
      return <StyledLabelWrapper className={valueClassName} {...props} />;
    case TYPES.ICON:
      return renderIcon({ className: valueClassName, ...props });

    default:
      return null;
  }
};

ComposedLabelValue.propTypes = {
  className: string,
  type: string,
};

ComposedLabelValue.defaultProps = {
  className: null,
  type: null,
};

export default ComposedLabelValue;
