import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIconImage } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--eshop-image';

const IconEshop = ({ className, image }) => (
  <BuildIconImage className={classnames(namespace, className)} src={image} alt="icon-eshop" lazyload="off" />
);

IconEshop.propTypes = {
  className: string,
  image: string,
};

export default IconEshop;
