import React from 'react';

import BaseModal from './components/base-modal';
import useModal from '../../hooks/use-modal';

const Modal = (props) => {
  const [isOpen, openModal, hideModal] = useModal();

  return <BaseModal {...props} isOpen={isOpen} openModal={openModal} hideModal={hideModal} />;
};

export default Modal;
