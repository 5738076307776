import React from 'react';
import { string, bool, shape, func, number } from 'prop-types';

import get from 'lodash/get';
import { Tooltip } from '@andes/tooltip';

import SearchBookmarkIcon from '../../../components-v2/icons/sources/search-bookmark';

const namespace = 'ui-search-alert';

const SaveSearchButton = ({
  saveNewSearchProps,
  infoTooltipTimesViewed,
  isTooltipOpen,
  infoTooltip,
  className,
  onClick,
  offsetY,
  offsetX,
}) => {
  const { handleClose } = infoTooltipTimesViewed;

  const buttonTitle = get(saveNewSearchProps, 'title_configuration.label.text', null);

  const { isAvailable } = infoTooltipTimesViewed;

  const isTooltipVisible = infoTooltip && isAvailable && isTooltipOpen;

  if (isTooltipVisible) {
    return (
      <Tooltip
        content={infoTooltip.text}
        side={infoTooltip.location}
        type={infoTooltip.type}
        trigger="no-trigger"
        onClose={handleClose}
        visible
        closable
        closeIconLabel="Close"
        offsetY={offsetY}
        offsetX={offsetX}
        className={className}
      >
        <button type="button" className={`${namespace}__btn-save-new-search`} onClick={onClick}>
          <SearchBookmarkIcon className={`${namespace}__icon-search`} />
          <p>{buttonTitle}</p>
        </button>
      </Tooltip>
    );
  }

  return (
    <button type="button" className={`${namespace}__btn-save-new-search`} onClick={onClick}>
      <SearchBookmarkIcon className={`${namespace}__icon-search`} />
      <p>{buttonTitle}</p>
    </button>
  );
};

SaveSearchButton.propTypes = {
  className: string,
  infoTooltip: shape({
    text: string,
    location: string,
    type: string,
  }),
  infoTooltipTimesViewed: shape({
    handleClose: func,
  }),
  isTooltipOpen: bool,
  offsetX: number,
  offsetY: number,
  saveNewSearchProps: shape({
    title_configuration: shape({
      label: shape({
        text: string,
      }),
    }),
  }),
  onClick: func,
};

export default SaveSearchButton;
