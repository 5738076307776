import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const ICON_ID = 'poly_full';
const namespace = 'ui-search-icon ui-search-icon--full';

const IconFull = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 100 32"
    roleIcon="img"
    description={props.description ?? 'full'}
    iconId={ICON_ID}
  />
);

IconFull.propTypes = {
  className: string,
  description: string,
};

export default IconFull;
