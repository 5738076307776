import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--play';
const ICON_ID = 'ic_play';

const IconPlay = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 13 16"
    iconId={ICON_ID}
    width="13"
    height="16"
  />
);

IconPlay.propTypes = {
  className: string,
  style: string,
};

export default IconPlay;
