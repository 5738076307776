const alertsRestClient = require('../client/restclient')({
  timeout: 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { registerErrorMetrics } = require('../../app/utils/register-error-metrics');
const { ALERTS_SERVICE_BASE_PATH } = require('../constants/paths');
const { ERROR_CATCHER } = require('../../app/constants');

class Service {
  static addAlert(alertCreationData) {
    return alertsRestClient
      .post(`${ALERTS_SERVICE_BASE_PATH}`, {
        data: {
          alertCreationData,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        registerErrorMetrics({
          errorType: 'try_catch_error',
          errorCatcher: ERROR_CATCHER.TRY_CATCH,
          errorMessage: error?.message,
          errorServiceName: 'alert',
        });
      });
  }
}

module.exports = Service;
