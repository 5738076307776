import React from 'react';
import { arrayOf, bool, string } from 'prop-types';

import FilterOfficialStore from '../../../../filters/filter-official-store';
import FilterHighlighted from '../../../../filters/highlighted-filter';
import RenderColorsValues from './colors-values';
import RenderValues from './render-values';
import { FILTERS } from '../../../../../constants';

const namespace = 'ui-search-filter';
const { TYPES, IDS } = FILTERS;

const FilterValuesContent = ({ id, name, type, values, expanded_values, currencies, isShops }) => {
  if (type === TYPES.HIGHLIGHTED) {
    return <FilterHighlighted id={id} values={values} expanded_values={expanded_values} />;
  }

  if (id === IDS.COLOR || id === IDS.FILTRABLE_COLOR) {
    return (
      <li>
        <ul className={`${namespace}-row wrap`}>
          {expanded_values.map((value) => (
            <RenderColorsValues
              key={value.id}
              id={value.id}
              namespace={namespace}
              name={value.name}
              results={value.results}
              url={value.url}
            />
          ))}
        </ul>
      </li>
    );
  }

  if (id === IDS.OFFICIAL_STORE && type === TYPES.WITH_PICTURES) {
    return <FilterOfficialStore id={id} values={values} expanded_values={expanded_values} name={name} />;
  }

  if (id === IDS.PRICE && currencies) {
    return null;
  }

  return expanded_values.map((ev) => <RenderValues {...ev} isShops={isShops} key={id} filterId={id} />);
};

FilterValuesContent.propTypes = {
  currencies: arrayOf,
  expanded_values: arrayOf,
  id: string.isRequired,
  isShops: bool,
  name: string.isRequired,
  type: string.isRequired,
  values: arrayOf,
};

export default FilterValuesContent;
