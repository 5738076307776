import { string, shape, bool } from 'prop-types';

const SkyBannerPropTypes = {
  google_ad: shape({
    unit: string,
    size: string,
    ppid: string,
    enabled: bool,
  }).isRequired,
  personal_data_ads_denied: string.isRequired,
  segmentation: shape({
    custID: string,
    Posiciones: string,
    platform: string,
  }).isRequired,
  slot_id: string.isRequired,
  title: string,
  viewport_margin: string,
};

export { SkyBannerPropTypes };
