import React from 'react';
import { bool } from 'prop-types';

import { useStaticProps } from '../context/static-props';
import IconFactory from '../../components-v2/icons/utils/factory-builder'; // TODO: check if we can be removed this component
import getBrandsAdsLayout from './helpers';

const BrandAdsDesktop = (props) => {
  const { lowEnd } = useStaticProps();
  const BrandAdsComponent = getBrandsAdsLayout(props);

  return <BrandAdsComponent {...props} IconFactory={IconFactory()} lowEnd={lowEnd} />;
};

BrandAdsDesktop.propTypes = {
  lowEnd: bool,
};

BrandAdsDesktop.defaultProps = {
  lowEnd: false,
};

export default BrandAdsDesktop;
