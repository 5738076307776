import { LABEL_COMPONENTS, ODR_BASE_URL } from '../constants';

const { ICON, PRICE, DEFAULT, LABEL } = LABEL_COMPONENTS;

const buildHighlightedLabelValues = (label) => {
  const values = [];

  if (label.icons && !!label.icons?.length) {
    const { icons, sr_label, style } = label;
    const idsDesestructured = icons?.[0]?.split('/');
    const iconId = idsDesestructured?.[0];
    const srcId = idsDesestructured?.[1];

    values.push({
      key: ICON.key,
      type: ICON.type,
      icon_id: iconId,
      src: `${ODR_BASE_URL}${srcId}`,
      description: sr_label,
      style,
    });
  }

  if (label.amount) {
    values.push({
      text: label?.amount?.price,
      key: PRICE.key,
      type: PRICE.type,
      html_tag: 'span',
      size: 12,
      currencyId: label?.amount?.currency_id,
    });
  }

  if (label?.text?.processed_text) {
    const { processed_text } = label.text;

    values.push({
      key: DEFAULT.key,
      type: DEFAULT.type,
      text: processed_text,
      styles: label.label?.styles,
    });
  }

  if (label?.suffix_text?.text?.processed_text) {
    const { text, className, styles } = label.suffix_text;
    const { processed_text } = text;

    values.push({
      key: LABEL.key,
      type: LABEL.type,
      text: processed_text,
      className,
      styles,
    });
  }

  return values;
};

export default buildHighlightedLabelValues;
