import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import getIcon from '../../components-v2/icons';
import StyledLabelWrapper from '../styled-label/styled-label';

const actionsByType = {
  icon: (value) => getIcon({ iconId: value.id }),
  text: (value) => <StyledLabelWrapper {...value} />,
};

const RichText = ({ className = null, text, values, ...props }) => {
  const renderPart = (part, index) => {
    if (part.startsWith('{') && part.endsWith('}')) {
      const key = part.slice(1, -1);
      const matchedValue = values.find((v) => v.key === key);

      if (matchedValue && actionsByType[matchedValue.type]) {
        return actionsByType[matchedValue.type](matchedValue, index);
      }
    }

    return <StyledLabelWrapper text={part} {...props} />;
  };

  if (values === null || values === undefined || values?.length === 0) {
    return <StyledLabelWrapper className={className} as="p" text={text} {...props} />;
  }

  return <p className={className}>{text.split(/({[^}]+})/).map(renderPart)}</p>;
};

RichText.propTypes = {
  className: string,
  text: string.isRequired,
  values: arrayOf(
    shape({
      id: string.isRequired,
      key: string.isRequired,
      type: string.isRequired,
    }),
  ),
};

export default RichText;
