import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const ICON_ID = 'poly_calendar';

const IconOnlineVisitRequest = (props) => (
  <BuildIcon
    {...props}
    viewBox="0 0 12 12"
    className={classnames(props.className)}
    iconId={ICON_ID}
    width="12"
    height="12"
  />
);

IconOnlineVisitRequest.propTypes = {
  className: string,
  style: string,
};

export default IconOnlineVisitRequest;
