import { shape, string } from 'prop-types';

const seoInfoImageTextBoxInterventionPropTypes = {
  intervention: shape({
    title: string.isRequired,
    description: string.isRequired,
    alt_image: string.isRequired,
    image_url: string.isRequired,
    expansion_text: string.isRequired,
    contraction_text: string.isRequired,
  }),
};

export { seoInfoImageTextBoxInterventionPropTypes };
