import React from 'react';

import EmptyState from 'ui-library_ml/ui/empty-state';

import { getMessagesErrorBySiteId } from '../../lib/get-message-error';
import IconLoaderError from '../../components-v2/icons/sources/map-loader-error';
import { useStaticProps } from '../context/static-props';

const namespace = 'ui-search-map-loader-error';

const LoaderError = () => {
  const { siteId } = useStaticProps();
  const { title, description } = getMessagesErrorBySiteId(siteId);

  return (
    <EmptyState
      className={namespace}
      icon={<IconLoaderError className={`${namespace}__icon`} />}
      title={title}
      description={description}
    />
  );
};

export default LoaderError;
