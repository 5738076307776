import React from 'react';
import { bool, oneOf, shape, string } from 'prop-types';

import classnames from 'classnames';

import RenderComponent from './utils/renderComponent';
import LabelContent from './components/label-content';
import { getVariablesFromText } from './utils/utilities';
import { PACKAGE_NAMESPACE_LABEL } from './constants';
import component from '../../components/snackbar/component';
import withComponentHandler from '../../hocs/with-component-handler';
/**
 * @typedef {Object} LabelBuilderProps
 * @property {string} [class_name] - Clases CSS adicionales para el componente.
 * @property {'p'|'span'|'h1'|'h2'|'h3'|'h4'|'li'} [html_tag] - La etiqueta HTML que se usará para renderizar el texto.
 * @property {Object} [styleLabel] - Los estilos aplicados al texto del componente.
 * @property {string} [styleLabel.color] - El color del texto.
 * @property {string} [styleLabel.font_family] - La familia tipográfica del texto.
 * @property {string} [styleLabel.font_size] - El tamaño de la fuente del texto.
 * @property {boolean} [styleLabel.isShop] - Indica si el texto pertenece a una tienda.
 * @property {Object} [styles] - Los estilos en línea aplicados al componente.
 * @property {string} [text] - El texto que se renderizará dentro del componente.
 * @property {string} [type] - El tipo de etiqueta que se renderizará.
 */

/**
 * Componente que renderiza un texto con estilos personalizados y etiquetas HTML dinámicas.
 *
 * @param {LabelBuilderProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el texto renderizado.
 */
const LabelBuilder = (props) => {
  const { class_name, html_tag, text, type, styleLabel = {}, styles } = props;

  const Interpolationkeys = getVariablesFromText(text);

  if (Interpolationkeys) {
    return <LabelContent {...props} className={class_name} />;
  }

  const Component = RenderComponent(type);
  const Element = html_tag || 'span';

  return (
    <Element
      className={classnames(
        PACKAGE_NAMESPACE_LABEL,
        { 'ui-search-styled-label--shops': styleLabel?.isShop },
        { [`ui-search-size--${styleLabel?.font_size}`]: styleLabel?.font_size },
        { [`ui-search-weight--${styleLabel?.font_family}`]: styleLabel?.font_family },
        class_name,
      )}
      style={styles}
    >
      {Component ? component({ ...props }) : text}
    </Element>
  );
};

LabelBuilder.propTypes = {
  class_name: string,
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  styleLabel: shape({
    color: string,
    font_family: string,
    font_size: string,
    isShop: bool,
  }),
  styles: shape({}),
  text: string,
  type: string,
};

export default withComponentHandler(LabelBuilder, { componentName: 'LabelBuilder' });
