import { ASSETS } from '../constants';

const ASSETS_FORMAT_REGEX = /\{icon\}|\{price\}/gi;
const ICON_REGEX = /\{(.*?)\}/;

const findIdPosition = (text) => {
  if (!text) {
    return null;
  }

  const match = text.match(ICON_REGEX);

  if (!match) {
    return { processed_text: text };
  }

  const textParts = text.trim() === '{icon}' ? [''] : text.split(ASSETS_FORMAT_REGEX);
  const icon_position = textParts[0] === '' ? ASSETS.POSITION.LEFT : ASSETS.POSITION.RIGHT;
  const processed_text = text.replace(ICON_REGEX, '');

  return {
    icon_position,
    processed_text,
  };
};

export default findIdPosition;
