import React from 'react';
import { COMPONENT_TYPE, PACKAGE_NAMESPACE } from './constants';
import { hasOwnProperty } from './utilities';
import SOURCES from '../label/utils/sources';
import StyledLabel from '../label/styled-label';

const { FN_ICON, JSX, SINGLE_TAG } = COMPONENT_TYPE;

const renderStyledLabel = (replacement, index) => (
  <StyledLabel {...replacement} key={`${PACKAGE_NAMESPACE}-pb-${index}`} />
);

const renderComponentsFromBuilder = (result, replacements, sourceIcons, accessibility_text) => {
  result.map((item, index) => {
    const replacement = replacements && replacements.find((replace) => hasOwnProperty(replace, `{${item}}`));

    if (replacement) {
      const { type } = replacement;

      const typeInUppercase = type?.toUpperCase();

      if (!typeInUppercase) return null;

      const { [typeInUppercase]: element } = SOURCES;

      if (element) {
        const { kind, Component } = element;

        if (kind === JSX) {
          result[index] = <Component {...replacement} key={`${PACKAGE_NAMESPACE}-pb-${index}`} />;
        } else if (kind === FN_ICON) {
          result[index] = Component({
            iconId: replacement?.icon_id, 
            description: replacement?.description, 
            width: replacement?.width,
            height: replacement?.height,
            color: replacement?.color, 
            key: replacement?.key,
            className:`${PACKAGE_NAMESPACE}-pb-${index}`,
          });
        } else if (kind === SINGLE_TAG) {
          result[index] = <Component key={`${PACKAGE_NAMESPACE}-pb-${index}`} />;
        } else {
          result[index] = renderStyledLabel(replacement, index);
        }
      } else {
        result[index] = renderStyledLabel(replacement, index);
      }
    } else if (item !== '') {
      result[index] = <StyledLabel text={item} key={`${PACKAGE_NAMESPACE}-pb-${index}`} accessibility_text={accessibility_text} />;
    }

    return null;
  });

  return result;
};

export default renderComponentsFromBuilder;
