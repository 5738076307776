import { useState } from 'react';

import { useStaticProps } from '../../../../components/context/static-props';
import { linkBuildNewHref } from '../../../link';
import { useLayout } from '../../../../hooks/context';
import redirectTo from '../../../../utils/redirect-to';

export const useDropdown = (values) => {
  const selectedOption = values.find(({ selected }) => selected);
  const defaultOption = values.length > 0 ? values[0] : undefined;
  const initialValue = selectedOption ?? defaultOption;

  const [itemSelected, setItemSelected] = useState(initialValue);

  const { queryParams } = useStaticProps();
  const { currentLayout } = useLayout();

  const onSelectItem = (e, item) => {
    const newValue = values?.find((value) => value?.id === item);

    if (newValue) {
      setItemSelected(newValue);

      if (!!newValue.url) {
        redirectTo(linkBuildNewHref({ href: newValue.url, queryParams, isInternal: true, currentLayout }));
      }
    }
  };

  return { itemSelected, onSelectItem };
};
