import { useStaticProps } from '../../../../components/context/static-props';
import { useLayout, useSearch } from '../../../../hooks/context';
import useMapConfig from '../../../../components/map/hooks/use-map-config';
import useFilterSearch from '../../../../hooks/use-filter-search';
import redirectTo from '../../../../utils/redirect-to';
import { trackClickEvent } from '../../../../components/map/track';
import { LAYOUTS } from '../../../../constants';

export const useHighlightsFilters = ({ setIsChecked, url, filterId }) => {
  const { queryParams } = useStaticProps();
  const { searchTrack } = useMapConfig();
  const { layout_options } = useSearch();
  const filterSearch = useFilterSearch(url, filterId);
  const isClientSideFilter = Boolean(filterSearch);
  const { currentLayout } = useLayout();

  const onClickHighlighted = () => {
    setIsChecked((prev) => !prev);

    if (isClientSideFilter) {
      filterSearch();

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, { melidata_track: { ...searchTrack, type: 'view' } });
      }

      return;
    }

    redirectTo(url, {
      useLinkBuilder: true,
      linkBuilderConfig: {
        queryParams,
        isInternal: true,
        currentLayout,
      },
    });
  };

  return { onClickHighlighted };
};
