import React from 'react';
import { string, shape, arrayOf, bool, func } from 'prop-types';

import classNames from 'classnames';
import get from 'lodash/get';

import Link from '../link';
import withComponentHandler from '../../hocs/with-component-handler';

const namespace = 'ui-search-money-picker-desktop';
const emptyArray = [];

/**
 * @typedef {Object} Currency
 * @property {string} id - The unique identifier of the currency.
 * @property {string} name - The name of the currency.
 * @property {string} url - The URL associated with the currency.
 * @property {number} results - The number of results for the currency.
 */

/**
 * @typedef {Object} MoneyPickerProps
 * @property {string} [className] - Additional class names for the component.
 * @property {Currency[]} [currencies] - List of available currencies.
 * @property {Object} [filterValues] - Filter values for the currencies.
 * @property {string} [title] - Title of the money picker.
 * @property {string|null} [activeCurrencyId] - The ID of the active currency.
 * @property {function} [handleCurrencyChange] - Function to handle currency change.
 */

/**
 * MoneyPicker component for selecting currencies.
 *
 * @param {MoneyPickerProps} props - The properties for the MoneyPicker component.
 * @returns {JSX.Element} The rendered MoneyPicker component.
 */

const MoneyPicker = ({
  className = '',
  currencies = emptyArray,
  filterValues = null,
  title = '',
  activeCurrencyId = null,
  handleCurrencyChange = null,
}) => {
  const createLiFilterValue = ({ id, name, url, results }) => (
    <li key={id} className={`${namespace}__li`}>
      <Link href={url} title={name}>
        <span className="ui-search-filter-name">{name}</span>
        <span className="ui-search-filter-results-qty">{results}</span>
      </Link>
    </li>
  );

  const renderValues = () => {
    const activeValues = get(filterValues, activeCurrencyId, []);

    return activeValues.map((it) => createLiFilterValue(it));
  };

  const handleClickMoneyPicker = (e, value) => {
    e.preventDefault();
    handleCurrencyChange(value.id);
  };

  return (
    <>
      <li>
        <h3 aria-level="3" className={`${namespace}-dt-title`}>
          {title}
        </h3>
        {currencies.length > 1 && (
          <div className={classNames(namespace, className)}>
            <div className={`${namespace}__content`}>
              {currencies.map((value) => (
                <button
                  type="button"
                  key={value.id}
                  className={`${namespace}__btn`}
                  id={value.id}
                  disabled={activeCurrencyId === value.id ? 'disabled' : ''}
                  onClick={(e) => {
                    handleClickMoneyPicker(e, value);
                  }}
                >
                  {value.symbol}
                </button>
              ))}
            </div>
          </div>
        )}
      </li>
      {filterValues && renderValues()}
    </>
  );
};

MoneyPicker.propTypes = {
  activeCurrencyId: string.isRequired,
  className: string,
  currencies: arrayOf(
    shape({
      id: string.isRequired,
      symbol: string.isRequired,
      selected: bool,
    }).isRequired,
  ),
  filterValues: shape().isRequired,
  handleCurrencyChange: func,
  title: string.isRequired,
};

export default withComponentHandler(MoneyPicker, { componentName: 'MoneyPicker-desktop' });
