import React from 'react';
import { string } from 'prop-types';

import Link from '../../../link';

/**
 * @typedef {Object} ProcessedDescriptionProps
 * @property {string} [id] - El identificador del elemento.
 * @property {string} [name] - El nombre del elemento.
 * @property {string} [url] - La URL asociada al elemento.
 */

/**
 * Componente que renderiza una descripción procesada con un enlace.
 *
 * @param {ProcessedDescriptionProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un enlace que contiene el nombre y el identificador del elemento.
 */
const ProcessedDescription = ({ id = '', name = '', url = '' }) => (
  <Link title={name} href={url}>
    <b>{name}</b>
    <br />({id})
  </Link>
);

ProcessedDescription.propTypes = {
  id: string,
  name: string,
  url: string,
};

export default ProcessedDescription;
