import React from 'react';
import { string } from 'prop-types';

import { BuildIcon } from '../utils';

const ICON_ID = 'chevron_right';

const IconChevronRight = (props) => (
  <BuildIcon {...props} viewBox="0 0 20 21" width="42" height="44" color={props.color ?? '#3483FA'} iconId={ICON_ID} />
);

IconChevronRight.propTypes = {
  color: string,
  style: string,
};

export default IconChevronRight;
