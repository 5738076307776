import React from 'react';
import { arrayOf, shape } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../../../hooks/context';
import FilterItem from './components/filter-item';
import withComponentHandler from '../../../../hocs/with-component-handler';

const namespace = 'ui-search-filter';

/**
 * Filters component to display a list of filters.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.filters - Array of filter objects.
 * @param {Array} props.labels - Array of label objects.
 * @returns {JSX.Element} The rendered component.
 */

const Filters = ({ filters: initialFilters, labels: initialLabels }) => {
  const filters = initialFilters || [];
  const labels = initialLabels || [];

  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    filters && (
      <section className={classnames(`${namespace}-groups`, { 'shops__filter-groups': isShops })}>
        {filters.map((filter) => (
          <FilterItem key={filter.id} {...filter} labels={labels} />
        ))}
      </section>
    )
  );
};

Filters.propTypes = {
  filters: arrayOf(shape({})),
  labels: arrayOf(shape({})),
};

export default withComponentHandler(Filters, { componentName: 'Filters', isCritical: true });
