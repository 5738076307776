import { loadable } from 'nordic/lazy';

import sources from '../constants/component-types';

const BottomSheetIframe = loadable(async () => import('./eshops-components-library'), {
  resolveComponent: (mod) => mod.withBottomSheetContext(mod.BottomSheetIframe),
});

const ProfileHeader = loadable(async () => import('./eshops-components-library'), {
  resolveComponent: (mod) => mod.withBottomSheetContext(mod.ProfileHeader),
});

const FreeShippingBar = loadable(async () => import('../../../components/full-snackbar/eshops-freeshipping.desktop'));

export default {
  [sources.PROFILE_HEADER]: ProfileHeader,
  [sources.FREE_SHIPPING_BAR]: FreeShippingBar,
  [sources.BOTTOM_SHEET_IFRAME]: BottomSheetIframe,
};
