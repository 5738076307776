import React, { useState } from 'react';
import { shape, string, bool, number } from 'prop-types';

import classnames from 'classnames';
import { Card } from '@andes/card';
import { Pill } from '@andes/badge';

import { MainImage } from '../../layout/renderer/map/common/main-image';
import { arePropsEqual } from '../../layout/renderer/map/card-component/helpers';
import { namespace } from '../../layout/renderer/constants';
import Link from '../../../components-v2/link';
import { useStaticProps } from '../../context/static-props';
import ItemBookmark from '../../../components-v2/bookmark/bookmark';
import { useSearch } from '../../../hooks/context';
import { verticalContentRenderer } from '../vertical-content';
import { PictureSwipe } from '../picture-swipe';
import ItemHighlight from '../../card/highlight/highlight-label';

const CardMobile = ({ item, options }) => {
  const { deviceType } = useStaticProps();
  const { bookmark } = useSearch();
  const [showPictures, setShowPictures] = useState(false);
  const { title, permalink, pictures, bookmarked, pictures_qty, float_highlight: floatHighlight } = item;

  const onPictureClick = () => {
    setShowPictures(item);
  };

  return (
    <>
      {showPictures && <PictureSwipe item={item} options={options} onClose={() => setShowPictures(false)} />}
      <Card animated={false} className={namespace}>
        <div className={`${namespace}__wrapper`}>
          <button type="button" className={`${namespace}__main-image-button`} onClick={onPictureClick}>
            {floatHighlight && <ItemHighlight {...floatHighlight} className={`${namespace}__seen-label`} />}
            <MainImage pictures={pictures} item={item} options={options} />
            <Pill color="gray" className={`${namespace}__main-image-button__total-pictures`}>
              {`1 / ${pictures_qty}`}
            </Pill>
          </button>
          <Link
            className={`${namespace}__content-link`}
            href={permalink}
            title={title}
            isInternal={false}
            target="_blank"
          >
            {verticalContentRenderer(item, deviceType, 2)}
          </Link>
          {bookmark?.show_user_bookmarks && (
            <div
              className={classnames(`${namespace}__bookmark`, {
                [`${namespace}__bookmark-active`]: bookmarked,
              })}
            >
              <ItemBookmark item={item} />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

CardMobile.propTypes = {
  float_highlight: shape({
    background: string,
    className: string,
    id: string,
    label: shape({
      text: string,
      color: string,
    }),
    type: string,
  }),
  item: shape({
    title: string.isRequired,
    permalink: string.isRequired,
    pictures: shape({}),
    bookmarked: bool.isRequired,
    pictures_qty: number.isRequired,
  }).isRequired,
  options: shape({
    lowEnd: bool,
  }),
};

CardMobile.defaultProps = {
  options: undefined,
  onPictureClick: () => undefined,
};

export default React.memo(CardMobile, arePropsEqual);
