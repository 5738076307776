import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';

import classnames from 'classnames';
import get from 'lodash/get';

import { onChangeLayout, useLayout } from '../../../context/layout';
import FilterSort from '../../../../components-v2/filters/filter-sort';
import ViewChange from '../../../view-change/view-change.desktop';
import { useSearch } from '../../../../hooks/context';
import { useStaticProps } from '../../../context/static-props';
import { MapButtonDesktop } from '../../../../components-v2/sidebar/components/map';
import Breadcrumbs from '../../../../components-v2/sidebar/components/breadcrumb';
import StyledLabelWrapper from '../../../styled-label/styled-label';
import { LAYOUTS } from '../../../../constants';
import types from '../../../factory/component-types';

const namespace = 'ui-search-view-options';
const LAYOUT_TYPE_GALLERY = 'gallery';

const getContainerClass = (layout) => {
  switch (layout) {
    case LAYOUTS.TYPE_MAP:
      return `${namespace}__container--map`;

    default:
      return `${namespace}__container`;
  }
};

const getGroupClass = (layout) => {
  switch (layout) {
    case LAYOUTS.TYPE_MAP:
      return `${namespace}__group--map`;

    default:
      return `${namespace}__group`;
  }
};

const ViewOptions = ({
  views,
  currentLayout,
  sort,
  className = '',
  mapLink = null,
  breadcrumbs = null,
  showViewChange = true,
  heading,
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const contentClass = classnames(`${namespace}__content`, className);
  const hasMapLink = !!mapLink;

  return (
    <div className={getContainerClass(currentLayout)}>
      {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
      {heading && <StyledLabelWrapper type={types?.ACCESIBLE_LABEL} text={heading} linkId="results" />}
      <div className={classnames(namespace, { 'shops__view-options': isShops })}>
        <div className={contentClass}>
          {hasMapLink && <MapButtonDesktop {...mapLink} />}
          <div className={getGroupClass(currentLayout)}>
            <div className={classnames(`${namespace}__title`, { 'shops-custom-primary-font': isShops })}>
              {sort.label}{' '}
            </div>
            <FilterSort {...sort} />
            {showViewChange && (
              <ViewChange onChangeLayout={onChangeLayout} views={views} currentLayout={currentLayout} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ViewOptions.propTypes = {
  breadcrumbs: shape({}),
  className: string.isRequired,
  currentLayout: string.isRequired,
  heading: string,
  mapLink: shape({}),
  showViewChange: bool,
  sort: shape({}).isRequired,
  values: arrayOf(
    shape({
      id: string,
      name: string,
      url: string,
      selected: bool,
    }),
  ).isRequired,
  views: Object.isRequired,
};

const ViewOptionsContainer = ({ sort, className = '', ...rest }) => {
  const { changeLayout } = useStaticProps();
  const contextValues = useSearch();

  let sortData = sort;
  let { heading } = rest;

  if (!sort) {
    sortData = contextValues.view_options ? contextValues.view_options.sort : {};
  }

  if (!heading) {
    heading = contextValues.view_options ? contextValues.view_options.heading : '';
  }

  const { views, currentLayout } = useLayout();

  // TODO: Borrar la siguiente línea cuando se arregle lo del middleware y usar únicamente el currentLayout.id
  // trello card: https://trello.com/c/W9sRFdMR/445-no-viene-el-currentlayout-en-en-algunos-listados-como-casa-auto-zapatillas-etc
  const currentLayoutID = currentLayout ? currentLayout.id : LAYOUT_TYPE_GALLERY;

  return (
    <ViewOptions
      sort={sortData}
      className={className}
      views={views}
      onChangeLayout={onChangeLayout}
      currentLayout={currentLayoutID}
      mapLink={contextValues.map_link}
      breadcrumbs={get(contextValues, 'map_configuration.breadcrumb')}
      showViewChange={changeLayout}
      heading={heading}
    />
  );
};

ViewOptionsContainer.propTypes = {
  className: string,
  heading: string,
  sort: shape({}).isRequired,
};

export default ViewOptionsContainer;
export { ViewOptions };
