import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-map-link';

const ICON_ID = 'icon_map_desktop';

const IconMap = (props) => (
  <BuildIcon
    {...props}
    dataTestId="link-map"
    viewBox="0 0 20 20"
    iconId={ICON_ID}
    className={classnames(namespace, props.className)}
    width="20"
    height="18"
  />
);

IconMap.propTypes = {
  className: string,
  style: string,
};

export default IconMap;
