import {
  useEshopsEnvironment as useEshopsEnvironmentBase,
  setEshopsEnvironment,
} from '../components/context/eshop-environment';

const useEshopsEnvironment = () => {
  const isEshopsEnvironment = useEshopsEnvironmentBase();

  return { isEshopsEnvironment, setEshopsEnvironment };
};

export default useEshopsEnvironment;
