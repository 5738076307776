import React from 'react';

import ErrorBoundary from '../components-v2/error/error-boundary';

/**
 * Props expected by the Higher-Order Component.
 *
 * @param {React.ComponentType} Component - The component to be wrapped.
 * @param {Object} state - Additional props to pass to the ErrorBoundary.
 * @returns {React.FC} - A new component that renders the wrapped component within an ErrorBoundary.
 */

const withComponentHandler = (Component, state) => {
  const HOC = (props) => (
    <ErrorBoundary {...state}>
      <Component {...props} />
    </ErrorBoundary>
  );

  return HOC;
};

export default withComponentHandler;
