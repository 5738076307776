import React from 'react';

import EshopBanner from './eshop/eshop-banner.desktop';
import useBanner from './use-banner';
import { HEADER_TYPES } from '../../components-v2/header/utils/constants';

const getBannerByType = {
  [HEADER_TYPES.ESHOP]: EshopBanner,
};

const BannerContainer = () => {
  const banner = useBanner();

  if (!banner) {
    return null;
  }

  const BannerComponent = getBannerByType[banner.sub_type] || null;

  return BannerComponent && <BannerComponent className={`ui-search-banner--${banner.sub_type}`} {...banner} />;
};

export default BannerContainer;
