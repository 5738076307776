import SOURCES from './sources';

const RenderComponent = (type) => {
  const typeInUppercase = type?.toUpperCase();

  const { [typeInUppercase]: element } = SOURCES;

  return element ?? null;
};

export default RenderComponent;
