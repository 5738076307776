import React from 'react';

import useTooltip from '../../../../hooks/use-tooltip';
import { MOBILE_DEFAULT_PROPS, MOBILE_PROP_TYPES } from './constants';
import { getFilterTooltipKey } from './helpers';
import { FilterTooltip } from './mobile';

const FilterTooltipContainer = ({ tooltip = MOBILE_DEFAULT_PROPS.tooltip, ...props }) => {
  const localStorageKey = getFilterTooltipKey(tooltip);
  const [tooltipStatus, handleClose, hasTTLExpired, handleOnNext, isTooltipLoad] = useTooltip(
    localStorageKey,
    tooltip?.ttl,
  );

  if (!isTooltipLoad) {
    return null;
  }

  return (
    <FilterTooltip
      {...props}
      tooltip={tooltip}
      tooltipStatus={tooltipStatus}
      onClose={handleClose}
      hasTTLExpired={hasTTLExpired}
      onPermanentClose={handleOnNext}
    />
  );
};

FilterTooltipContainer.propTypes = MOBILE_PROP_TYPES;

export default FilterTooltipContainer;
