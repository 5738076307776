import React, { useState, useMemo } from 'react';
import { string } from 'prop-types';

import constuctClassName from 'classnames';

import { FilterTooltip } from '../../../sidebar/components/filters-tooltip/mobile';
import usePageLoad from '../../../../hooks/use-page-load';
import getIcon from '../../../icons';
import HighlightedWrapper from './highlighted-desktop-wrapper';
import { useHighlightsFilters } from '../hooks/use-highlights-filters';
import { useSearch } from '../../../../hooks/context';
import { FILTER_EXTENDED_PROPS, MELI_PLUS, NAMESPACE, QUESTION_ICON_ID } from '../constants';
import processTextWithAssets from '../../../../utils/process-text-with-assets';
import SwitchFilter from '../../../switch-filter';
/**
 * @typedef {Object} HighlightedFilterProps
 * @property {string} filterId - El identificador del filtro destacado.
 * @property {Object} switch - El estado del interruptor asociado al filtro.
 * @property {boolean} switch.is_on - Indica si el interruptor está activado.
 * @property {Object|null} highlight - Información adicional sobre el filtro destacado.
 * @property {Object|null} label - El texto del label asociado al filtro.
 * @property {Object|null} subtitle - El subtítulo del filtro.
 * @property {Object|null} title - El título del filtro.
 * @property {Array|null} title.icons - Los íconos asociados al título.
 * @property {Object|null} tooltip - El contenido del tooltip asociado al filtro.
 * @property {string} url - La URL asociada al filtro.
 */

/**
 * Componente que renderiza un filtro destacado con interruptor, título, subtítulo y tooltip.
 *
 * @param {HighlightedFilterProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el filtro destacado.
 */
const HighlightedFilter = ({
  filterId,
  switch: switchValue,
  highlight = null,
  label = null,
  subtitle = null,
  title = null,
  tooltip = null,
  url = '',
}) => {
  const { is_on: isOnSwitch } = switchValue || {};
  const [isChecked, setIsChecked] = useState(isOnSwitch);
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const isPageLoaded = usePageLoad();
  const { onClickHighlighted } = useHighlightsFilters({ setIsChecked, url, filterId });

  const getTitleClassname = useMemo(
    () =>
      constuctClassName(`${NAMESPACE}__title`, {
        [`${NAMESPACE}__${MELI_PLUS}`]:
          title && Array.isArray(title.icons) && title?.icons?.[0] === `${MELI_PLUS}_icon`,
      }),
    [title],
  );

  const HighlightedContent = (
    <>
      <SwitchFilter
        className={constuctClassName(`${NAMESPACE}__switch`, { 'shops__highlighted-switch': isShops })}
        checked={isChecked}
        id={filterId}
        title={{ ...title, text: processTextWithAssets(title?.text), className: getTitleClassname }}
        subtitle={{ ...subtitle, text: processTextWithAssets(subtitle?.text), className: `${NAMESPACE}__subtitle` }}
        labelPosition="left"
        onChange={() => onClickHighlighted()}
        highlight={{
          ...highlight,
          ...(highlight?.suffix_text && {
            suffix_text: {
              ...highlight.suffix_text,
              text: processTextWithAssets(highlight?.suffix_text?.text),
            },
          }),
          text: processTextWithAssets(highlight?.label?.text),
        }}
        label={{ ...label, text: processTextWithAssets(label?.text) }}
      />
      {tooltip && (
        <div className={`${NAMESPACE}__tooltip`}>
          <FilterTooltip tooltip={{ ...tooltip, side: 'right' }} onClose={() => {}}>
            <div className={`${NAMESPACE}__tooltip-trigger`} />
            {getIcon({ iconId: QUESTION_ICON_ID })}
          </FilterTooltip>
        </div>
      )}
    </>
  );

  return (
    <HighlightedWrapper namespace={NAMESPACE} isShops={isShops} isPageLoaded={isPageLoaded} url={url}>
      {HighlightedContent}
    </HighlightedWrapper>
  );
};

HighlightedFilter.propTypes = {
  ...FILTER_EXTENDED_PROPS,
  filterId: string.isRequired,
};

export default HighlightedFilter;
